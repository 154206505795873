<mat-card>
    <mat-card-header>
        <mat-card-title>
            Mijn toetsen
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <fieldset>
            <legend>Toets activeren</legend>
            <mat-form-field>
                <input matInput placeholder="Wachtwoord" [(ngModel)]="examActivationCode"
                    (keyup.enter)="getExamByPassphrase()">
            </mat-form-field>
            <app-button-with-spinner (click)="getExamByPassphrase()" [isLoading]="isLoading$"
                [disabled]="isSubmitDisabled">
                Wachtwoord invoeren
            </app-button-with-spinner>
        </fieldset>

        <mat-table [dataSource]="examSessions$ | async">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Toets</mat-header-cell>
                <mat-cell *matCellDef="let examSession">
                    {{ examSession.examTitle }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="examInstanceCustomTitle">
                <mat-header-cell *matHeaderCellDef>Titel</mat-header-cell>
                <mat-cell *matCellDef="let examSession">
                    {{ examSession.examInstanceCustomTitle }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="finishedDate">
                <mat-header-cell *matHeaderCellDef>Gemaakt op</mat-header-cell>
                <mat-cell *matCellDef="let examSession">
                    {{ examSession.finishedAt ?? examSession.openUntil | date }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="score">
                <mat-header-cell *matHeaderCellDef>Score</mat-header-cell>
                <mat-cell *matCellDef="let examSession">
                    <span *ngIf="isFinalizedExamSession(examSession)">{{ formatGrade(examSession) }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Acties</mat-header-cell>
                <mat-cell *matCellDef="let examSession">
                    <ng-container *ngIf="isFinalizedExamSession(examSession); else continueExam">
                        <button mat-raised-button (click)="showExamResult(examSession)">
                            Score
                        </button>
                    </ng-container>
                    <ng-template #continueExam>
                        <a mat-raised-button color="primary" [routerLink]="['/exam-session', examSession.id, 'start' ]">
                            Verder gaan
                        </a>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </mat-card-content>
</mat-card>