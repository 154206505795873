import { Injectable } from '@angular/core';
import {
  authState,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export abstract class FireAuthService {
  static readonly authState = authState;
  static readonly signInWithCustomToken = signInWithCustomToken;
  static readonly signInWithPopup = signInWithPopup;
  static readonly signOut = signOut;
}
