import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const authGuard = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.authState().pipe(
    filter((user) => user !== undefined), // Wait for initial load
    map((user) => {
      if (user) {
        return true;
      }

      if (state.url && state.url !== '/') {
        router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
      } else {
        router.navigate(['login']);
      }
      return false;
    }),
    first()
  );
};
