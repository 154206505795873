import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'environments/environment';
import {
  interactionReducer,
  InteractionState,
} from 'src/app/store/reducers/interaction.reducer';
import { scoreReducer, ScoreState } from 'src/app/store/reducers/score.reducer';
import {
  sharedReducer,
  SharedState,
} from 'src/app/store/reducers/shared.reducer';
import {
  snackbarReducer,
  SnackbarState,
} from 'src/app/store/reducers/snackbar.reducer';
import {
  NavStructureState,
  structureReducer,
} from 'src/app/store/reducers/structure.reducer';
import {
  teacherDashboardReducer,
  TeacherDashboardState,
} from 'src/app/store/reducers/teacher-dashboard.reducer';
import { userReducer } from 'src/app/store/reducers/user.reducer';
import {
  CalculatorFeatureState,
  calculatorReducer,
} from './calculator.reducer';
import { examReducer, ExamState } from './exam.reducer';
import { NotepadFeatureState, notepadReducer } from './notepad.reducer';
import {
  studentExamsReducer,
  StudentExamsState,
} from './student-exams.reducer';
import { studentReducer, StudentState } from './student.reducer';
import { UserState } from './user.reducer';

export interface AppState {
  shared: SharedState;
  structure: NavStructureState;
  user: UserState;
  interaction: InteractionState;
  calculator: CalculatorFeatureState;
  examState: ExamState;
  router: RouterReducerState;
  snackbar: SnackbarState;
  score: ScoreState;
  teacherDashboard: TeacherDashboardState;
  notepad: NotepadFeatureState;
  student: StudentState;
  studentExams: StudentExamsState;
}

export const reducers: ActionReducerMap<AppState> = {
  shared: sharedReducer,
  structure: structureReducer,
  user: userReducer,
  interaction: interactionReducer,
  calculator: calculatorReducer,
  examState: examReducer,
  router: routerReducer,
  snackbar: snackbarReducer,
  score: scoreReducer,
  teacherDashboard: teacherDashboardReducer,
  notepad: notepadReducer,
  student: studentReducer,
  studentExams: studentExamsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
