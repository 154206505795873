import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Project } from 'shared/models/project';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { educationTrackFilter } from 'src/app/helpers/education-track-filter';
import { structureActions } from 'src/app/store/actions/structure.actions';
import { AppState } from 'src/app/store/reducers';
import { selectEducationTrack } from 'src/app/store/reducers/shared.reducer';
import { environment } from '../../../../environments/environment';
import { EntityType } from '../../../../shared/enums/entity-type';
import { StructuralEntity } from '../../../../shared/models/structural-entity';
import {
  DatabaseService,
  GetScoreParams,
} from '../../services/database.service';
import { LocalStorageService } from '../../services/local-storage.service';
import {
  LastUsedLocation,
  selectLastUsedLocation,
  selectStructureState,
} from '../../store/reducers/structure.reducer';
import {
  selectIsContentAdminMode,
  selectIsDbUserLoaded,
  selectUser,
  selectUserIsTeacher,
  selectUserUid,
} from '../../store/reducers/user.reducer';
import { RouteData } from '../../types/route-data';

export type EntityWithProgress = StructuralEntity & {
  scoreParams: GetScoreParams;
};

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  entities$: Observable<StructuralEntity[]>;
  lastUsedLocation$: Observable<LastUsedLocation>;
  entitiesWithProgress$: Observable<EntityWithProgress[]>;
  parentScoreParams: GetScoreParams;

  navType: EntityType;
  childType: EntityType;
  entityTypes = EntityType;

  contentAdminMode$: Observable<boolean>;
  refresh$ = new BehaviorSubject<void>(null);

  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  userUid$: Observable<string>;
  reviewModePaths$: Observable<{ [key: string]: string[] }>;
  userIsTeacher$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private activatedRoute: ActivatedRoute
  ) {
    this.entities$ = this.store.select(selectStructureState);
    this.lastUsedLocation$ = this.store.select(selectLastUsedLocation);
    this.userUid$ = this.store.select(selectUserUid);
    this.userIsTeacher$ = this.store.select(selectUserIsTeacher);

    this.reviewModePaths$ = combineLatest([this.entities$, this.userUid$]).pipe(
      map(([entities, uid]) => {
        const paths: { [key: string]: string[] } = {};
        entities?.forEach((entity) => {
          const path = [entity.id, 'presentation', '1', uid];
          paths[entity.id] = path;
        });
        return paths;
      })
    );
  }

  // TODO make global handler.
  onImageError(event: any) {
    event.target.src = './assets/images/no-image.png';
  }

  ngOnInit() {
    this.entitiesWithProgress$ = combineLatest([
      this.activatedRoute.paramMap,
      this.entities$,
      this.store.select(selectUserUid),
      this.store.select(selectEducationTrack),
    ]).pipe(
      map(([paramMap, entities, uid, educationTrack]) => {
        const projectId = paramMap.get(AppRouteParams.projectId);
        const domainId = paramMap.get(AppRouteParams.domainId);
        const chapterId = paramMap.get(AppRouteParams.chapterId);

        const entitiesMapped: EntityWithProgress[] = entities.map((entity) => ({
          ...entity,
          scoreParams: {
            uid,
            projectId:
              projectId || (entity.type === EntityType.project && entity.id),
            domainId:
              domainId || (entity.type === EntityType.domain && entity.id),
            chapterId:
              chapterId || (entity.type === EntityType.chapter && entity.id),
          },
        }));

        return [entitiesMapped, educationTrack] as [
          EntityWithProgress[],
          Project['educationTrack']
        ];
      }),
      map(([entities, educationTrack]) =>
        educationTrackFilter(entities, educationTrack)
      ),
      takeUntil(this.ngDestroyed$)
    );

    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.data as Observable<RouteData>,
      this.store.select(selectUser),
      this.store.select(selectIsDbUserLoaded),
      this.refresh$,
    ])
      .pipe(
        filter(([paramMap, data, user, userLoaded]) => !!userLoaded),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(([paramMap, data, user]) => {
        const parentEntityId =
          paramMap.get(AppRouteParams.chapterId) ||
          paramMap.get(AppRouteParams.domainId) ||
          paramMap.get(AppRouteParams.projectId);
        this.navType = data.navType;
        this.childType = data.childType;

        if (!environment.production) {
          console.log(
            `EntityType: ${data.navType}, Entity: ${parentEntityId}, ChildType ${data.childType}`
          );
        }

        if (parentEntityId) {
          this.store.dispatch(
            structureActions.loadChildrenForEntity(parentEntityId, data.navType)
          );

          this.parentScoreParams = {
            projectId: paramMap.get(AppRouteParams.projectId),
            domainId: paramMap.get(AppRouteParams.domainId),
            chapterId: paramMap.get(AppRouteParams.chapterId),
          };
        } else {
          if (data.navType === EntityType.project) {
            this.store.dispatch(structureActions.loadProjectsForUser(user));
          } else {
            this.store.dispatch(
              structureActions.loadEntitiesOfType(data.navType)
            );
          }
        }
      });

    this.contentAdminMode$ = this.store
      .select(selectIsContentAdminMode)
      .pipe(takeUntil(this.ngDestroyed$));
  }

  isLastUsedProject(
    entity: StructuralEntity,
    lastUsedLocation: LastUsedLocation
  ): boolean {
    return (
      !!lastUsedLocation &&
      entity.type === EntityType.project &&
      entity.id === lastUsedLocation.projectId
    );
  }

  getLastUsedLocationPath(
    lastUsedLocation: LastUsedLocation
  ): [string, string, string] {
    if (!lastUsedLocation) {
      return null;
    }

    return [
      lastUsedLocation.projectId,
      lastUsedLocation.domainId,
      lastUsedLocation.chapterId,
    ];
  }

  entitySetDisabled(
    entityId: StructuralEntity['id'],
    entityType: EntityType,
    disabled: boolean
  ) {
    this.databaseService
      .entitySetDisabled(entityId, entityType, disabled)
      .then(() => {
        LocalStorageService.removeEntityFromCache(entityId, entityType);
        if (entityType === EntityType.unit) {
          LocalStorageService.removeUnitFromCache(entityId);
        }
        this.refresh$.next();
      })
      .catch(() => {
        alert(
          `Error while ${
            disabled ? 'disabling' : 'enabling'
          } ${entityType} ${entityId}. See console for details.`
        );
      });
  }

  displayEntityText(entity: StructuralEntity) {
    const text = `${entity.title || entity.id} ${
      entity.type === EntityType.interaction
        ? entity.name + ' ' + entity.model
        : ''
    }`;

    if (entity.subtitle) {
      return text.replace(entity.subtitle, '');
    }

    return text;
  }

  onReviewClick(entityId: string) {
    const paths = this.reviewModePaths$.pipe(
      map((paths) => {
        const path = paths[entityId];
        console.log('Clicked review for entity', entityId);
        console.log('Full review path:', path?.join('/'));
        return path;
      })
    );
    paths.subscribe();
  }

  ngOnDestroy() {
    this.store.dispatch(structureActions.reset());
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
