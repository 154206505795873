import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Exam } from 'shared/models/exam';
import { ExamService } from 'src/app/services/exam.service';
import { EducationTrack } from '../../../../../../../shared/models/project';
import { AppState } from '../../../../../store/reducers/index';
import { selectEducationTrack } from '../../../../../store/reducers/shared.reducer';

@Component({
  selector: 'app-exam-overview-teacher-setup',
  templateUrl: './exam-overview-teacher-setup.component.html',
  styleUrls: ['./exam-overview-teacher-setup.component.scss'],
})
export class ExamOverviewTeacherSetupComponent implements OnInit {
  examColumns = [
    {
      field: 'name',
      title: 'Toets',
    },
    {
      field: 'actions',
      title: 'Acties',
    },
  ];

  examDisplayedColumns = this.examColumns.map((c) => c.field);
  exams$: Observable<Exam[]>;
  educationTrack$: Observable<EducationTrack>;

  constructor(
    private examService: ExamService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.educationTrack$ = this.store.select(selectEducationTrack);

    this.exams$ = this.educationTrack$.pipe(
      first(),
      switchMap((eduTrack) => this.examService.getExams(eduTrack))
    );
  }
}
