export enum AppRouteParams {
  uid = 'uid',
  projectId = 'projectId',
  domainId = 'domainId',
  chapterId = 'chapterId',
  unitId = 'unitId',
  unitContext = 'unitContext',
  interactionIndex = 'interactionIndex',
  interactionId = 'interactionId',
  loginProvider = 'loginProvider',
  examId = 'examId',
  examInstanceId = 'examInstanceId',
  examSessionId = 'examSessionId',
  examSectionIndex = 'examSectionIndex',
  examSectionInteractionIndex = 'examSectionInteractionIndex',
}
