export const typeOfNull = 'Null';
export const typeOfUndefined = 'Undefined';
export const typeOfNumber = 'Number';
export const typeOfFunction = 'Function';
export const typeOfString = 'String';

export const isTypeOf = <T>(item: T, type: string) =>
  Object.prototype.toString.call(item) === `[object ${type}]`;

export const isUndefined = <T>(item: T): boolean =>
  isTypeOf(item, typeOfUndefined);

export const isNull = <T>(item: T): boolean => isTypeOf(item, typeOfNull);

export const isNullOrUndefined = <T>(item: T): boolean =>
  isUndefined(item) || isNull(item);

export const isNotNullOrUndefined = <T>(item: T): boolean =>
  isNullOrUndefined(item) === false;
