import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { buildUserUnitScorePath } from '../../../../shared/models/user-unit-score';

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
  selectTitle,
} = getRouterSelectors();

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectUnitPath = createSelector(selectRouteParams, (params) =>
  params
    ? {
        projectId: params[AppRouteParams.projectId] as string,
        domainId: params[AppRouteParams.domainId] as string,
        chapterId: params[AppRouteParams.chapterId] as string,
        unitId: params[AppRouteParams.unitId] as string,
      }
    : null
);

export const selectUnitPathFromRouteParams = createSelector(
  selectRouteParams,
  (params) =>
    params
      ? {
          projectId: params[AppRouteParams.projectId] as string,
          domainId: params[AppRouteParams.domainId] as string,
          chapterId: params[AppRouteParams.chapterId] as string,
          unitId: params[AppRouteParams.unitId] as string,
        }
      : null
);

export const selectInteractionIdFromRouteParams = selectRouteParam(
  AppRouteParams.interactionId
);

export const selectUnitIdFromRouteParams = selectRouteParam(
  AppRouteParams.unitId
);

export const selectUserUnitScorePath = createSelector(
  selectUnitPath,
  (unitPath: {
    projectId: string;
    domainId: string;
    chapterId: string;
    unitId: string;
  }) => {
    if (!unitPath) {
      return null;
    }

    const { projectId, domainId, chapterId, unitId } = unitPath;

    if (!projectId || !domainId || !chapterId || !unitId) {
      return null;
    }

    return buildUserUnitScorePath({
      projectId,
      domainId,
      chapterId,
      unitId,
    });
  }
);

// Determines if we're viewing a student's interaction (review mode)
export const selectIsInteractionReviewMode = createSelector(
  selectRouteParams,
  selectUrl,
  (params, url) => {
    // Case 1: Unit interaction review mode
    const isUnitReview = !!(
      params?.[AppRouteParams.unitId] &&
      params?.[AppRouteParams.unitContext] &&
      params?.[AppRouteParams.interactionIndex] &&
      params?.[AppRouteParams.uid]
    );

    // Case 2: Exam session review mode
    const isExamSessionReview = url?.includes('exam-session-review/');

    return isUnitReview || isExamSessionReview;
  }
);
