import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFiller',
})
export class ArrayFillerPipe implements PipeTransform {
  transform = (value: number) =>
    Array(value)
      .fill(1)
      .map((x, i) => i + 1);
}
