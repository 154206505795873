import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { studentActions } from '../actions/student.actions';

export const studentFeatureKey = 'student';

export interface StudentState {
  uid?: string;
}

export const initialState: StudentState = {
  uid: null,
};

export const reducer = createReducer(
  initialState,
  on(
    studentActions.setStudent,
    (state, { uid }): StudentState => ({ ...state, uid })
  ),
  on(studentActions.unsetStudent, (state) => initialState)
);

export const studentReducer = (
  state: StudentState | undefined,
  action: Action
) => reducer(state, action);

export const selectStudentFeature =
  createFeatureSelector<StudentState>(studentFeatureKey);

export const selectStudentState = createSelector(
  selectStudentFeature,
  (state) => state
);
export const selectStudentUid = createSelector(
  selectStudentFeature,
  (state) => state.uid
);
