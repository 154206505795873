import { phoneticTransform } from '../../../../shared/helpers/phonetic';
import { regularExpressions } from '../../../../shared/helpers/regex';
import { Interaction } from '../../../../shared/models/interaction';
import {
  InteractionOptionHotspot,
  InteractionOptionImage,
  InteractionOptionText,
  Order,
  correctnessSorter,
} from '../../../../shared/models/interaction-option';
import { Point } from '../../../../shared/types/shapes';

interface TextTransformOptions {
  isPhonetic: boolean;
  caseSensitive: boolean;
  ignorePunctuation: boolean;
  ignoreSpaces: boolean;
}

const stripWhitespace = (value: string): string => {
  return value.replace(regularExpressions.whitespace, '');
};

const stripPunctuation = (value: string): string => {
  return value.replace(regularExpressions.punctuation, '');
};

/**
 * Transforms text based on the provided options:
 * - Trims whitespace
 * - Applies phonetic transformation or case normalization
 * - Removes punctuation and/or spaces as required
 */
const transformText = (
  input: string,
  options: TextTransformOptions
): string => {
  let result = input.trim();

  if (options.isPhonetic) {
    result = phoneticTransform(result);
  } else if (!options.caseSensitive) {
    result = result.toLowerCase();
  }

  if (options.ignorePunctuation) {
    result = stripPunctuation(result);
  }

  if (options.ignoreSpaces) {
    result = stripWhitespace(result);
  }

  return result;
};

/**
 * Compares numeric answers after processing:
 * - Trims whitespace and removes thousand separators if needed
 * - Replaces commas with dots to standardize decimal notation
 * - Handles special comparison cases (>, <, or range with <<)
 */
const compareNumericAnswers = (
  answer: string,
  optionStr: string,
  ignoreSpaces: boolean
): boolean => {
  let processedAnswer = answer.trim();
  let processedOption = optionStr.trim();

  if (ignoreSpaces) {
    processedAnswer = processedAnswer.replace(
      regularExpressions.possibleThousandSeparator,
      ''
    );
    processedOption = processedOption.replace(
      regularExpressions.possibleThousandSeparator,
      ''
    );
  }

  // Standardize decimal separator
  processedAnswer = processedAnswer.replace(/,/g, '.');
  processedOption = processedOption.replace(/,/g, '.');

  // Handle special numeric comparisons
  if (processedOption.startsWith('>')) {
    return +processedAnswer > +processedOption.substring(1);
  } else if (processedOption.startsWith('<')) {
    return +processedAnswer < +processedOption.substring(1);
  } else if (processedOption.includes('<<')) {
    const [lower, upper] = processedOption.split('<<');
    return +processedAnswer >= +lower && +processedAnswer <= +upper;
  }

  return +processedAnswer === +processedOption;
};

export const matchInteractionOption = (
  answer: string | InteractionOptionHotspot,
  answerIndex: number,
  parsedInteraction: Interaction
):
  | InteractionOptionText
  | InteractionOptionImage
  | InteractionOptionHotspot
  | Order
  | undefined => {
  if (parsedInteraction.optionsText) {
    return matchInteractionTextOption(
      answer as string,
      answerIndex,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsImage) {
    return matchInteractionImageOption(
      answer as string,
      answerIndex,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsHotspot) {
    return matchInteractionHotspotOption(
      answer as InteractionOptionHotspot,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsSortOrder) {
    return matchInteractionSortOrderOption(answer as string, parsedInteraction);
  }
};

export const matchInteractionTextOption = (
  answer: string,
  answerIndex: number,
  parsedInteraction: Interaction
): InteractionOptionText | undefined => {
  if (!parsedInteraction.optionsText) {
    throw new Error('optionsText is required for text interaction matching');
  }

  const {
    numeric: isNumeric,
    ignoreSpaces,
    phonetic: isPhonetic,
    ignorePunctuation,
    caseSensitive,
  } = parsedInteraction;

  // Create a sorted copy for deterministic matching
  const optionsText = [...parsedInteraction.optionsText].sort(
    correctnessSorter
  );

  return optionsText.find((option) => {
    // If a field is specified, only process when it matches the answerIndex
    if (!!option.field && answerIndex !== +option.field) {
      return false;
    }

    if (isNumeric) {
      return compareNumericAnswers(answer, option.option, ignoreSpaces);
    }

    const transformOptions: TextTransformOptions = {
      isPhonetic,
      caseSensitive,
      ignorePunctuation,
      ignoreSpaces,
    };

    const transformedAnswer = transformText(answer, transformOptions);
    const transformedOption = transformText(option.option, transformOptions);

    return transformedAnswer === transformedOption;
  });
};

export const matchInteractionImageOption = (
  answer: string,
  answerIndex: number,
  parsedInteraction: Interaction
): InteractionOptionImage | undefined => {
  if (!parsedInteraction.optionsImage) {
    throw new Error('optionsImage is required for image interaction matching');
  }

  const point = new Point(+answer.split(',')[0], +answer.split(',')[1]);

  const optionsImage = parsedInteraction.optionsImage
    .slice()
    .sort(correctnessSorter);

  return optionsImage.find((option) => option.shape?.isPointInside(point));
};

export const matchInteractionHotspotOption = (
  option: InteractionOptionHotspot,
  parsedInteraction: Interaction
): InteractionOptionHotspot | undefined => {
  if (!parsedInteraction.hotspots) {
    throw new Error('hotspots is required for hotspot interaction matching');
  }

  // We are returning a mutated copy
  const matchedOption = {
    ...option,
  };

  const hotspots = parsedInteraction.hotspots;

  if (!matchedOption.draggedTo) {
    throw new Error('draggedTo point is required for hotspot matching');
  }

  // Find hotspots where the option collides.
  const matchingHotspots = hotspots.filter((hotspot) => {
    if (!hotspot.shape) {
      throw new Error('hotspot shape is required for collision detection');
    }

    if (!matchedOption.draggedTo) {
      throw new Error('draggedTo point is required for hotspot matching');
    }

    return hotspot.shape.isPointInside(matchedOption.draggedTo);
  });

  if (matchingHotspots) {
    matchedOption.correct = !!matchingHotspots.find((hotspot) => {
      if (hotspot.id) {
        return hotspot.id === matchedOption.target;
      } else if (matchedOption.target) {
        return hotspots[+matchedOption.target - 1] === hotspot;
      } else {
        return (
          matchedOption.index !== undefined &&
          hotspots[matchedOption.index] === hotspot
        );
      }
    });

    matchedOption.feedback =
      (matchedOption.correct && matchedOption.feedbackCorrect) ||
      matchedOption.feedbackFalse;
  }

  return matchingHotspots && matchedOption;
};

export const matchInteractionSortOrderOption = (
  order: string,
  parsedInteraction: Interaction
): Order | undefined => {
  if (!parsedInteraction.order) {
    throw new Error('order is required for sort order interaction matching');
  }
  return parsedInteraction.order.find((o) => o.option === order);
};
