import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntityType } from '../../shared/enums/entity-type';
import { UserRole } from '../../shared/enums/user-role.enum';
import { AdminComponent } from './components/admin/admin.component';
import { ExamOverviewStudentComponent } from './components/exams/exam-overview-student/exam-overview-student.component';
import { ExamOverviewTeacherInstancesComponent } from './components/exams/exam-overview-teacher/components/exam-overview-teacher-instances/exam-overview-teacher-instances.component';
import { ExamOverviewTeacherSetupComponent } from './components/exams/exam-overview-teacher/components/exam-overview-teacher-setup/exam-overview-teacher-setup.component';
import { ExamOverviewTeacherStudentsComponent } from './components/exams/exam-overview-teacher/components/exam-overview-teacher-students/exam-overview-teacher-students.component';
import { ExamOverviewTeacherComponent } from './components/exams/exam-overview-teacher/exam-overview-teacher.component';
import { ExamResultsTeacherComponent } from './components/exams/exam-results-teacher/exam-results-teacher.component';
import { ExamSessionSubComponent } from './components/exams/exam-session/exam-session-sub/exam-session-sub.component';
import { ExamSessionComponent } from './components/exams/exam-session/exam-session.component';
import { ReadyExamComponent } from './components/exams/ready-exam/ready-exam.component';
import { ExportConvertorComponent } from './components/export-convertor/export-convertor.component';
import { GroupsComponent } from './components/groups/groups.component';
import { ImportComponent } from './components/import/import.component';
import { InteractionComponent } from './components/interaction/interaction.component';
import { LicenseExpiredComponent } from './components/license-expired/license-expired.component';
import { LicensesComponent } from './components/licenses/licenses.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/nav/nav.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { TeacherDashboardComponent } from './components/teacher-dashboard/teacher-dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { AppRouteParams } from './enums/route-params.enum';
import { authGuard } from './misc/auth.guard';
import { ExamSessionDataResolver } from './misc/exam-session-resolver.resolver';
import { licenseGuard } from './misc/license.guard';
import { roleGuard } from './misc/role.guard';
import { AppRoutes } from './types/route-data';

export const appRoutes: AppRoutes = [
  {
    path: '',
    pathMatch: 'full',
    component: NavComponent,
    data: { navType: EntityType.project },
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `login/:${AppRouteParams.loginProvider}`,
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  // TODO: add some useful filtering or remove
  {
    path: 'interactions',
    component: NavComponent,
    data: { navType: EntityType.interaction },
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `i/:${AppRouteParams.interactionId}`,
    component: InteractionComponent,
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `interaction/:${AppRouteParams.interactionId}`,
    component: InteractionComponent,
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `interactions/:${AppRouteParams.interactionId}`,
    component: InteractionComponent,
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: 'import',
    component: ImportComponent,
    canActivate: [authGuard, roleGuard],
    data: { roles: new Set([UserRole.admin, UserRole.developer]) },
  },
  {
    path: 'export-convertor',
    component: ExportConvertorComponent,
    canActivate: [authGuard, roleGuard],
    data: { roles: new Set([UserRole.admin, UserRole.developer]) },
  },
  {
    path: 'dashboard',
    component: TeacherDashboardComponent,
    canActivate: [authGuard, roleGuard],
    data: {
      roles: new Set([UserRole.admin, UserRole.teacher]),
    },
  },
  {
    path: 'exams-teacher',
    component: ExamOverviewTeacherComponent,
    canActivate: [authGuard, roleGuard],
    data: {
      roles: new Set([UserRole.teacher]),
    },
    children: [
      {
        path: '',
        redirectTo: 'instances',
        pathMatch: 'full',
      },
      {
        path: 'instances',
        component: ExamOverviewTeacherInstancesComponent,
        data: {
          label: 'Gemaakte toetsen',
        },
      },
      {
        path: 'students',
        component: ExamOverviewTeacherStudentsComponent,
        data: {
          label: 'Per student',
        },
      },
      {
        path: 'setup',
        component: ExamOverviewTeacherSetupComponent,
        data: {
          label: 'Toetsen klaarzetten',
        },
      },
    ],
  },
  {
    path: `exam-results/:${AppRouteParams.examInstanceId}`,
    component: ExamResultsTeacherComponent,
    canActivate: [authGuard, roleGuard],
    data: { roles: new Set([UserRole.teacher]) },
  },
  {
    path: 'exams-student',
    component: ExamOverviewStudentComponent,
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `ready-exam/:${AppRouteParams.examId}`,
    component: ReadyExamComponent,
    canActivate: [authGuard, roleGuard],
    data: {
      roles: new Set([UserRole.teacher]),
    },
  },
  {
    path: `exam-session/:${AppRouteParams.examSessionId}`,
    component: ExamSessionComponent,
    canActivate: [authGuard, licenseGuard],
    resolve: { examSession: ExamSessionDataResolver },
    children: [
      {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full',
      },
      {
        path: 'start',
        component: ExamSessionSubComponent,
        data: {
          flavor: 'start',
        },
      },
      {
        path: 'end',
        component: ExamSessionSubComponent,
        data: {
          flavor: 'end',
        },
      },
      {
        path: `:${AppRouteParams.examSectionIndex}/:${AppRouteParams.examSectionInteractionIndex}`,
        component: InteractionComponent,
      },
    ],
  },
  // Review mode
  {
    path: `exam-session-review/:${AppRouteParams.examSessionId}`,
    component: ExamSessionComponent,
    canActivate: [authGuard, licenseGuard],
    resolve: { examSession: ExamSessionDataResolver },
    children: [
      {
        path: '',
        redirectTo: '0/0',
        pathMatch: 'full',
      },
      {
        path: 'start',
        component: ExamSessionSubComponent,
        data: {
          flavor: 'start',
        },
      },
      {
        path: `:${AppRouteParams.examSectionIndex}/:${AppRouteParams.examSectionInteractionIndex}`,
        component: InteractionComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [authGuard, roleGuard],
    data: {
      roles: new Set([UserRole.admin, UserRole.teacher, UserRole.developer]),
    },
    children: [
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Gebruikers',
          roles: new Set([UserRole.admin]),
        },
      },
      {
        path: 'organizations',
        component: OrganizationComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Scholen',
          roles: new Set([UserRole.admin]),
        },
      },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Groepen',
          roles: new Set([UserRole.admin, UserRole.teacher]),
        },
      },
      {
        path: 'licenses',
        component: LicensesComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Licenties',
          roles: new Set([UserRole.admin]),
        },
      },
      {
        path: 'import',
        component: ImportComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Import',
          roles: new Set([UserRole.admin, UserRole.developer]),
        },
      },
      {
        path: 'export-convertor',
        component: ExportConvertorComponent,
        canActivate: [authGuard, roleGuard],
        data: {
          label: 'Export convertor',
          roles: new Set([UserRole.admin, UserRole.developer]),
        },
      },
    ],
  },
  { path: 'license-expired', component: LicenseExpiredComponent },
  {
    path: `:${AppRouteParams.projectId}/:${AppRouteParams.domainId}/:${AppRouteParams.chapterId}`,
    component: NavComponent,
    data: { navType: EntityType.chapter, childType: EntityType.unit },
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `:${AppRouteParams.projectId}/:${AppRouteParams.domainId}`,
    component: NavComponent,
    data: { navType: EntityType.domain, childType: EntityType.chapter },
    canActivate: [authGuard, licenseGuard],
  },
  {
    path: `:${AppRouteParams.projectId}`,
    component: NavComponent,
    data: { navType: EntityType.project, childType: EntityType.domain },
    canActivate: [authGuard, licenseGuard],
  },
  {
    path:
      `:${AppRouteParams.projectId}/:${AppRouteParams.domainId}/:${AppRouteParams.chapterId}/` +
      `:${AppRouteParams.unitId}/:${AppRouteParams.unitContext}/:${AppRouteParams.interactionIndex}`,
    component: InteractionComponent,
    canActivate: [authGuard, licenseGuard],
  },
  // Review mode
  {
    path:
      `:${AppRouteParams.projectId}/` +
      `:${AppRouteParams.domainId}/` +
      `:${AppRouteParams.chapterId}/` +
      `:${AppRouteParams.unitId}/` +
      `:${AppRouteParams.unitContext}/` +
      `:${AppRouteParams.interactionIndex}/` +
      `:${AppRouteParams.uid}`,
    component: InteractionComponent,
    canActivate: [authGuard, roleGuard],
    data: {
      roles: new Set([UserRole.admin, UserRole.teacher]),
    },
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
