export interface ValidCSVData {
  toString: () => string;
}

const triggerDownload = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadCSV = (
  data: ValidCSVData[][],
  filename: string,
  separatorChar: string = ';'
) => {
  const csvString = data.map((row) => row.join(separatorChar)).join('\n');
  const blob = new Blob([csvString], { type: 'text/csv' });
  triggerDownload(blob, filename + '.csv');
};

export const downloadJSON = (data: any, filename: string) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  });
  triggerDownload(blob, filename + '.json');
};
