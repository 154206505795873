import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { hideSnackbar, showSnackbar } from '../actions/snackbar.actions';

export interface SnackbarState {
  message: string;
  duration?: number;
  action?: string;
  isOpen: boolean;
}

export const initialSnackbarState: SnackbarState = {
  message: null,
  duration: null,
  action: null,
  isOpen: false,
};

const defaultAction = 'Ok';
const defaultDuration = 6000;

const snackbarReducerInternal = createReducer(
  initialSnackbarState,
  on(showSnackbar, (state, { message, duration, action }): SnackbarState => {
    const applyDefaults = duration === undefined && action === undefined;
    return {
      ...state,
      message,
      duration: applyDefaults ? defaultDuration : duration,
      action: applyDefaults ? defaultAction : action,
      isOpen: true,
    };
  }),
  on(hideSnackbar, (state): SnackbarState => initialSnackbarState)
);

export const snackbarReducer = (state: SnackbarState, action: Action) =>
  snackbarReducerInternal(state, action);

// Selectors
export const selectSnackbarFeatureState =
  createFeatureSelector<SnackbarState>('snackbar');

export const selectSnackbarState = createSelector(
  selectSnackbarFeatureState,
  (state) => state
);
