import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCamelCase',
})
export class SplitCamelCasePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    return (
      (typeof value === 'string' &&
        value.replace(/([^A-Z])([A-Z])/g, '$1 $2')) ||
      ''
    );
  }
}
