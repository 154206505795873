import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Exam } from 'shared/models/exam';
import { ExamInstance } from 'shared/models/exam-instance';
import { ExamResultFilter } from 'shared/models/exam-result-filter';
import { EducationTrack } from 'shared/models/project';
import { ExamService } from 'src/app/services/exam.service';
import { AppState } from 'src/app/store/reducers/index';
import { selectEducationTrack } from 'src/app/store/reducers/shared.reducer';

type ExamInstanceRow = ExamInstance & {
  finishedExamSessions: number;
  examName: string;
  showPassword?: boolean;
};

@Component({
  selector: 'app-exam-overview-teacher-instances',
  templateUrl: './exam-overview-teacher-instances.component.html',
  styleUrls: ['./exam-overview-teacher-instances.component.scss'],
})
export class ExamOverviewTeacherInstancesComponent
  implements OnInit, OnDestroy
{
  isLoading$ = new BehaviorSubject<boolean>(false);
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedExam = new FormControl<string>('');
  startDate = new FormControl<Date>(
    new Date(new Date().setDate(new Date().getDate() - 14))
  );
  endDate = new FormControl<Date>(null);
  minCompleted = new FormControl<number>(0);
  myExamsOnly = new FormControl<boolean>(true);

  searchTrigger$ = new ReplaySubject<void>(1);

  examResultColumns = [
    {
      field: 'examName',
      title: 'Toets',
    },
    {
      field: 'customTitle',
      title: 'Titel',
    },
    {
      field: 'openFrom',
      title: 'Datum van',
      type: 'date',
    },
    {
      field: 'openTo',
      title: 'Datum tot',
      type: 'date',
    },
    {
      field: 'finishedExamSessions',
      title: 'Aantal gemaakt',
    },
    {
      field: 'teacherId',
      title: 'Docent',
      type: 'user',
    },
    {
      field: 'passphrase',
      title: 'Wachtwoord',
      type: 'password',
    },
    {
      field: 'duration',
      title: 'Duur',
      type: 'duration',
    },
    {
      field: 'actions',
      title: 'Acties',
      type: 'actions',
    },
  ];

  examResultDisplayedColumns = this.examResultColumns.map((c) => c.field);
  examResults$: Observable<ExamInstanceRow[]>;
  exams$: Observable<Exam[]>;
  educationTrack$: Observable<EducationTrack>;

  constructor(
    private examService: ExamService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    const activeExamInstances$ = this.searchTrigger$.pipe(
      startWith(null as any),
      map(() => this.createFilterPayload()),
      switchMap((filterPayload) => {
        this.isLoading$.next(true);
        return from(this.examService.getExamInstances(filterPayload)).pipe(
          finalize(() => {
            this.isLoading$.next(false);
          })
        );
      })
    );

    this.educationTrack$ = this.store.select(selectEducationTrack);

    this.exams$ = this.educationTrack$.pipe(
      first(),
      switchMap((eduTrack) => this.examService.getExams(eduTrack))
    );

    // Initialize search on component initialization
    this.search();

    this.examResults$ = combineLatest([activeExamInstances$, this.exams$]).pipe(
      map(([examResults, exams]) =>
        examResults
          .map((result) => ({
            ...result,
            examName: exams.find((exam) => exam.id === result.examId)?.name,
          }))
          .filter(
            (result) =>
              !this.selectedExam.value ||
              result.examId === this.selectedExam.value
          )
          .filter(
            (result) =>
              !this.minCompleted.value ||
              result.finishedExamSessions >= this.minCompleted.value
          )
      ),
      catchError((error) => {
        console.error('Error fetching exam results:', error);
        return of([]);
      }),
      takeUntil(this.ngDestroyed$)
    );

    // Subscribe to filter changes
    this.selectedExam.valueChanges
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(() => this.search());
  }

  search = () => {
    this.searchTrigger$.next();
  };

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  private createFilterPayload = () => {
    const payload: ExamResultFilter = {};

    if (this.startDate.value) {
      payload.fromDate = this.startDate.value.valueOf();
    }

    if (this.endDate.value) {
      const endOfDay = new Date(this.endDate.value);
      endOfDay.setHours(23, 59, 59, 999);
      payload.toDate = endOfDay.valueOf();
    }

    payload.myExamsOnly = this.myExamsOnly.value;

    if (this.selectedExam.value) {
      payload.examId = this.selectedExam.value;
    }

    if (this.minCompleted.value !== null) {
      payload.minCompleted = this.minCompleted.value;
    }

    return payload;
  };

  togglePasswordVisibility(row: ExamInstanceRow): void {
    row.showPassword = !row.showPassword;
  }
}
