import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotepadLineSource } from 'shared/models/notepad-line';

export const notepadActions = createActionGroup({
  source: 'Notepad',
  events: {
    add: props<{ id: string; content: string; source: NotepadLineSource }>(),
    update: props<{ id: string; content: string }>(),
    remove: props<{ id: string }>(),
    toggleDialog: emptyProps(),
    openDialog: emptyProps(),
    closeDialog: props<{ clear: boolean }>(),
    reset: emptyProps(),
    setDialogState: props<{ isOpen: boolean }>(),
    setReadonlyMode: props<{ isReadonlyMode: boolean }>(),
  },
});
