export type TotalScores = {
  totalScore: number;
  maxTotalScore: number;
};

export const calculateExamSessionGrade = (
  scoreObject: TotalScores
): number | null => {
  const percentage = calculateExamSessionScorePercentage(scoreObject);
  return percentage !== null ? percentage / 10 : null;
};

export const calculateExamSessionScorePercentage = (
  scoreObject: TotalScores
): number | null => {
  const { totalScore, maxTotalScore } = scoreObject;

  if (
    typeof totalScore !== 'number' ||
    typeof maxTotalScore !== 'number' ||
    maxTotalScore === 0
  ) {
    return null;
  }

  return Math.round((totalScore / maxTotalScore) * 100);
};
