<mat-card>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link *ngFor="let link of navLinks$ | async" [routerLink]="link.path" routerLinkActive
            #rla="routerLinkActive" [active]="rla.isActive">
            {{link.data?.label}}
        </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
        <mat-card-content>
            <router-outlet></router-outlet>
        </mat-card-content>
    </mat-tab-nav-panel>
</mat-card>