import { Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  styleUrls: ['./button-with-spinner.component.scss'],
})
export class ButtonWithSpinnerComponent {
  @Input() icon?: string;
  @Input() disabled = false;
  isLoading$: Observable<boolean>;

  @Input() set isLoading(value: Observable<boolean> | boolean) {
    if (value instanceof Observable) {
      this.isLoading$ = value;
    } else {
      this.isLoading$ = new BehaviorSubject<boolean>(value);
    }
  }
}
