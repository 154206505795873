import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

@Directive({
  selector: '[appLinkedDatepickers]',
})
export class LinkedDatepickersDirective implements OnInit, OnDestroy {
  @Input() startDate: FormControl<Date>;
  @Input() endDate: FormControl<Date>;

  private ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  ngOnInit() {
    const startDateTimestamp$ = this.startDate.valueChanges.pipe(
      startWith(this.startDate.value),
      debounceTime(300),
      map((date: Date) => date?.valueOf()),
      distinctUntilChanged(),
      takeUntil(this.ngDestroyed$)
    );

    const endDateTimestamp$ = this.endDate.valueChanges.pipe(
      startWith(this.endDate.value),
      debounceTime(300),
      map((date: Date) => date?.valueOf()),
      distinctUntilChanged(),
      takeUntil(this.ngDestroyed$)
    );

    let lastStartDate: number;
    combineLatest([startDateTimestamp$, endDateTimestamp$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([startDate, endDate]) => {
        if (startDate && endDate && startDate > endDate) {
          if (lastStartDate === startDate) {
            this.startDate.setValue(new Date(endDate));
          } else {
            this.endDate.setValue(new Date(startDate));
          }
        }

        lastStartDate = startDate;
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
