<mat-table [dataSource]="exams$ | async">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Toets</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{ row.name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Acties</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <a mat-stroked-button [routerLink]="['/ready-exam', row.id]">Klaarzetten</a>
        </mat-cell>
    </ng-container>
    <mat-header-row mat-header-row *matHeaderRowDef="examDisplayedColumns; sticky: true"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: examDisplayedColumns;"></mat-row>
</mat-table>
<span *ngIf="(exams$ | async)?.length === 0" class="placeholder-text">
    Geen toetsen beschikbaar.
</span>