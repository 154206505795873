/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type AudioState = {
  muted: boolean;
  volume: number;
};

const defaultVolume = 0.5;

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  public readonly muted$: Observable<boolean>;
  public readonly volume$: Observable<number>;
  public readonly playing$: Observable<boolean>;

  private readonly _muted$: BehaviorSubject<boolean>;
  private readonly _volume$: BehaviorSubject<number>;
  private readonly _playing$: BehaviorSubject<boolean>;

  private player: HTMLAudioElement;

  constructor() {
    this.player = new Audio();
    this.player.addEventListener('play', () => {
      this._playing$.next(true);
    });
    this.player.addEventListener('pause', () => {
      this._playing$.next(false);
    });

    this.player.volume = defaultVolume;

    this._muted$ = new BehaviorSubject(this.player.muted);
    this._volume$ = new BehaviorSubject(this.player.volume);
    this._playing$ = new BehaviorSubject(false);

    this.muted$ = this._muted$.asObservable();
    this.volume$ = this._volume$.asObservable();
    this.playing$ = this._playing$.asObservable();
  }

  play(url: string = null) {
    if (url) {
      this.load(url);
    }

    const playPromise = this.player.play();
    playPromise.catch((error) => console.warn(error));
    return playPromise;
  }

  pause() {
    this.player.pause();
  }

  toggleMute(mute?: boolean) {
    if (typeof mute === 'boolean') {
      this.player.muted = mute;
    } else {
      this.player.muted = !this.player.muted;
    }

    this._muted$.next(this.player.muted);
  }

  togglePlay() {
    if (this.player.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  load(url: string) {
    this.player.src = url;
    this.player.load();
  }

  setVolume(value: number) {
    this.player.volume = Math.max(0, Math.min(1, value));
    this._volume$.next(this.player.volume);
  }

  increaseVolume(step: number = 0.1) {
    this.setVolume(this.player.volume + step);
  }

  decreaseVolume(step: number = 0.1) {
    this.setVolume(this.player.volume - step);
  }
}
