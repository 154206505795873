import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoute } from '../../../types/route-data';

@Component({
  selector: 'app-exam-overview-teacher',
  templateUrl: './exam-overview-teacher.component.html',
  styleUrls: ['./exam-overview-teacher.component.scss'],
})
export class ExamOverviewTeacherComponent implements OnInit {
  navLinks$: Observable<AppRoute[]>;

  constructor(private router: Router) {}

  ngOnInit() {
    // Get child routes from router config
    this.navLinks$ = new Observable<AppRoute[]>((subscriber) => {
      const examTeacherRoute = this.router.config.find(
        (r) => r.component === ExamOverviewTeacherComponent
      );
      const childRoutes =
        examTeacherRoute?.children?.filter((route) => route.path !== '') || [];
      subscriber.next(childRoutes);
      subscriber.complete();
    });
  }
}
