import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Exam, ExamSection } from 'shared/models/exam';
import { ExamInstance } from 'shared/models/exam-instance';
import { ExamSession, ExamSessionSection } from 'shared/models/exam-session';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { examActions } from 'src/app/store/actions/exam.actions';
import { InteractionScore } from '../../../../shared/models/score.enum';
import { selectCurrentRoute } from '../selectors/router.selectors';

export type ExamState = Readonly<{
  exam: Exam | null;
  examInstance: ExamInstance | null;
  examSession: ExamSession | null;
  isStarting: boolean;
  isSubmitting: boolean;
}>;

export const initialExamState: ExamState = {
  exam: null,
  examInstance: null,
  examSession: null,
  isStarting: false,
  isSubmitting: false,
};

const reducer = createReducer<ExamState>(
  initialExamState,
  on(
    examActions.startExamSession,
    (state: ExamState): ExamState => ({
      ...state,
      isStarting: true,
    })
  ),
  on(
    examActions.examSessionStarted,
    (state: ExamState, { examSession }): ExamState => ({
      ...state,
      examSession,
      isStarting: false,
    })
  ),
  on(
    examActions.startExamSessionFailed,
    (state: ExamState): ExamState => ({
      ...state,
      isStarting: false,
    })
  ),
  on(
    examActions.examLoaded,
    (state: ExamState, payload): ExamState => ({
      ...state,
      exam: payload.exam,
    })
  ),
  on(
    examActions.examInstanceLoaded,
    (state: ExamState, payload): ExamState => ({
      ...state,
      examInstance: payload.examInstance,
    })
  ),
  on(
    examActions.examSessionLoaded,
    (state: ExamState, { examSession }): ExamState => ({
      ...state,
      examSession,
    })
  ),
  on(
    examActions.submitExamSession,
    (state: ExamState): ExamState => ({
      ...state,
      isSubmitting: true,
    })
  ),
  on(
    examActions.submitExamSessionFailed,
    (state: ExamState): ExamState => ({
      ...state,
      isSubmitting: false,
    })
  ),
  on(
    examActions.examSessionCompleted,
    (state: ExamState, { examSessionResult }): ExamState => ({
      ...state,
      isSubmitting: false,
    })
  ),
  on(
    examActions.examSessionComponentDestroyed,
    (state: ExamState): ExamState => ({
      ...initialExamState,
      isStarting: state.isStarting,
      isSubmitting: state.isSubmitting,
    })
  ),
  on(
    examActions.handleExamInteractionAnswer,
    (state: ExamState, payload): ExamState => {
      const updatedInteractions = {
        ...state.examSession?.sections[payload.sectionIndex]?.interactions,
        [payload.interactionIndex]: payload.attempt,
      };

      const sections = { ...state.examSession.sections };

      const updatedSection = {
        ...sections[payload.sectionIndex],
        interactions: updatedInteractions,
      };

      const updatedSections = {
        ...sections,
        [payload.sectionIndex]: updatedSection,
      };

      const examSession = {
        ...state.examSession,
        sections: updatedSections,
      };

      return {
        ...state,
        examSession,
      };
    }
  ),
  on(
    examActions.storeShuffledVarsIndexes,
    (state: ExamState, payload): ExamState => {
      const updatedInteraction = {
        ...state.examSession?.sections[payload.sectionIndex]?.interactions[
          payload.interactionIndex
        ],
        varsShuffledIndexes: payload.varsShuffledIndexes,
      };

      const updatedInteractions = {
        ...state.examSession?.sections[payload.sectionIndex]?.interactions,
        [payload.interactionIndex]: updatedInteraction,
      };

      const sections = { ...state.examSession.sections };

      const updatedSection = {
        ...sections[payload.sectionIndex],
        interactions: updatedInteractions,
      };

      const updatedSections = {
        ...sections,
        [payload.sectionIndex]: updatedSection,
      };

      const examSession = {
        ...state.examSession,
        sections: updatedSections,
      };

      return {
        ...state,
        examSession,
      };
    }
  )
);

export const examReducer = (state: ExamState | undefined, action: Action) =>
  reducer(state, action);

// Selectors
export const selectExamState = createFeatureSelector<ExamState>('examState');

export const selectExam = createSelector(
  selectExamState,
  (state: ExamState) => state.exam
);

export const selectExamInstance = createSelector(
  selectExamState,
  (state: ExamState) => state.examInstance
);

export const selectExamSession = createSelector(
  selectExamState,
  (state: ExamState) => state.examSession
);

export const selectExamSessionId = createSelector(
  selectExamSession,
  (state: ExamSession) => state?.id
);

export const selectIsExamSession = createSelector(
  selectExamSession,
  (examSession: ExamSession) => !!examSession
);

export const selectIsSessionStarted = createSelector(
  selectExamSession,
  (examSession: ExamSession) => !!examSession?.startedAt
);

export const selectIsSessionStarting = createSelector(
  selectExamState,
  (state: ExamState) => !!state?.isStarting
);

export const selectCurrentExamSectionIndex = createSelector(
  selectCurrentRoute,
  (route) => {
    const examSectionIndex = route?.params?.[AppRouteParams.examSectionIndex];

    return examSectionIndex ? +examSectionIndex : null;
  }
);

export const selectCurrentExamSectionInteractionIndex = createSelector(
  selectCurrentRoute,
  (route) => {
    const examSectionInteractionIndex =
      route?.params?.[AppRouteParams.examSectionInteractionIndex];

    return examSectionInteractionIndex ? +examSectionInteractionIndex : null;
  }
);

export const selectAnswerContext = createSelector(
  selectExamSessionId,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  (
    examSessionId,
    examSectionIndex,
    examInteractionIndex
  ): ExamAnswerContext => ({
    examSessionId,
    examSectionIndex,
    examInteractionIndex,
  })
);

export const selectNextExamInteractionRoute = createSelector(
  selectExam,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  (exam, sectionIndex, interactionIndex) => {
    const section = exam?.sections[sectionIndex];

    if (!section) {
      return null;
    }

    const nextInteractionIndex = interactionIndex + 1;

    if (nextInteractionIndex < section.interactions.length) {
      return `${sectionIndex}/${nextInteractionIndex}`;
    }

    const nextSectionIndex = sectionIndex + 1;

    if (nextSectionIndex < exam.sections.length) {
      return `${nextSectionIndex}/0`;
    }

    return null;
  }
);

export const selectPrevExamInteractionRoute = createSelector(
  selectExam,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  (exam, sectionIndex, interactionIndex) => {
    const section = exam?.sections[sectionIndex];

    if (!section) {
      return null;
    }

    const prevInteractionIndex = interactionIndex - 1;

    if (prevInteractionIndex >= 0) {
      return `${sectionIndex}/${prevInteractionIndex}`;
    }

    const prevSectionIndex = sectionIndex - 1;

    if (prevSectionIndex >= 0) {
      const prevSection = exam.sections[prevSectionIndex];

      return `${prevSectionIndex}/${prevSection.interactions.length - 1}`;
    }

    return null;
  }
);

export const selectCurrentExamInteractionId = createSelector(
  selectExam,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  (exam, sectionIndex, interactionIndex) => {
    const section = exam?.sections[sectionIndex];

    if (!section) {
      return null;
    }

    const interaction = section.interactions[interactionIndex];

    return interaction?.interactionId;
  }
);

export const selectCurrentExamSessionInteractionAttempt = createSelector(
  selectExamSession,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  (session, sectionIndex, interactionIndex) => {
    const section = session?.sections[sectionIndex];

    if (!section) {
      return null;
    }

    return section.interactions[interactionIndex];
  }
);

export const selectExamSections = createSelector(
  selectExam,
  (exam) => exam?.sections
);

export const selectUnansweredExamQuestionsCount = createSelector(
  selectExamState,
  (state) => {
    if (!state?.examSession || !state?.exam?.sections) {
      return null;
    }

    const getQuestionCount = (sections: { [s: number]: ExamSection }) =>
      Object.entries(sections).reduce(
        (sum, x) => sum + Object.keys(x[1].interactions).length,
        0
      );

    const getAnsweredQuestionsCount = (sections: {
      [s: number]: ExamSessionSection;
    }) =>
      Object.entries(sections).reduce(
        (sum, x) =>
          sum +
          Object.entries(x[1].interactions).filter(
            (i) => i[1].answers?.length ?? 0 > 0
          ).length,
        0
      );

    const answered = getAnsweredQuestionsCount(state.examSession.sections);
    const total = getQuestionCount(state.exam.sections);
    return total - answered;
  }
);

export const selectExamSessionSections = createSelector(
  selectExamSession,
  (session) => session?.sections
);

export type ExamAnswerContext = {
  examSessionId: string;
  examSectionIndex: number;
  examInteractionIndex: number;
};

export const selectShowPrevExamInteractionButton = createSelector(
  selectIsExamSession,
  selectPrevExamInteractionRoute,
  (isExamSession, prevRoute) => isExamSession && !!prevRoute
);

export const selectShowNextExamInteractionButton = createSelector(
  selectIsExamSession,
  selectNextExamInteractionRoute,
  (isExamSession, nextRoute) => isExamSession && !!nextRoute
);

export const createSelectIsInteractionAttempted = ({
  sectionIndex,
  interactionIndex,
}: {
  sectionIndex: number;
  interactionIndex: number;
}) =>
  createSelector(
    selectExamSession,
    (examSession: ExamSession | null): boolean =>
      !!examSession?.sections[sectionIndex]?.interactions[interactionIndex]
        ?.answers?.length
  );

export const createSelectIsInteractionCorrect = ({
  sectionIndex,
  interactionIndex,
}: {
  sectionIndex: number;
  interactionIndex: number;
}) =>
  createSelector(
    selectExamSession,
    (examSession: ExamSession | null): boolean =>
      examSession?.sections[sectionIndex]?.interactions[interactionIndex]
        ?.score === InteractionScore.correct
  );

export const createSelectIsInteractionIncorrect = ({
  sectionIndex,
  interactionIndex,
}: {
  sectionIndex: number;
  interactionIndex: number;
}) =>
  createSelector(
    selectExamSession,
    (examSession: ExamSession | null): boolean =>
      examSession?.sections[sectionIndex]?.interactions[interactionIndex]
        ?.score === InteractionScore.incorrect
  );
