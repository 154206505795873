import { Injectable, inject } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { NotepadLineSource } from 'shared/models/notepad-line';
import {
  NotepadDialogComponent,
  NotepadDialogData,
} from 'src/app/components/notepad';
import { calculatorActions } from '../actions/calculator.actions';
import { interactionActions } from '../actions/interaction.actions';
import { notepadActions } from '../actions/notepad.actions';
import { selectIsInteractionReviewMode } from '../selectors/router.selectors';

@Injectable()
export class NotepadEffects {
  private readonly actions$ = inject(Actions);
  private readonly dialog = inject(MatDialog);
  private readonly store = inject(Store);

  private notepadDialogRef: MatDialogRef<NotepadDialogComponent>;

  openNotepadDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notepadActions.openDialog),
        tap(() => {
          this.openDialog();
        })
      ),
    {
      dispatch: false,
    }
  );

  toggleNotepadDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notepadActions.toggleDialog),
        tap(() => {
          this.toggleDialog();
        })
      ),
    {
      dispatch: false,
    }
  );

  closeNotepadDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notepadActions.closeDialog),
      filter(() => !!this.notepadDialogRef),
      tap(() => {
        this.notepadDialogRef.close();
      }),
      map((action) => {
        if (action.clear) {
          return notepadActions.reset();
        }
      })
    )
  );

  clearForNewInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.loadInteraction),
      map(() => notepadActions.reset())
    )
  );

  addCalculationWithResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculatorActions.calculationResult),
      map((calculationResultAction) => {
        const content = `${calculationResultAction.input}=${calculationResultAction.result}`;

        const addToNotepadAction = notepadActions.add({
          id: crypto.randomUUID(),
          content,
          source: NotepadLineSource.calculator,
        });

        return addToNotepadAction;
      })
    )
  );

  // Set readonly mode when route changes
  setReadonlyOnReviewMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this.store.select(selectIsInteractionReviewMode)),
      map(([_, isReviewMode]) =>
        notepadActions.setReadonlyMode({ isReadonlyMode: isReviewMode })
      )
    )
  );

  private openDialog(): void {
    const data: NotepadDialogData = {};
    if (!this.notepadDialogRef) {
      this.notepadDialogRef = this.dialog.open<NotepadDialogComponent>(
        NotepadDialogComponent,
        {
          data,
          hasBackdrop: false,
          panelClass: NotepadDialogComponent.panelClass,
          position: {
            top: '75px',
            left: '950px',
          },
          autoFocus: false,
          id: NotepadDialogComponent.panelClass,
        }
      );
      this.store.dispatch(notepadActions.setDialogState({ isOpen: true }));

      this.notepadDialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(() => {
          this.notepadDialogRef = null;
          this.store.dispatch(notepadActions.setDialogState({ isOpen: false }));
        });
    }
  }

  private toggleDialog(): void {
    const data: NotepadDialogData = {};
    if (!this.notepadDialogRef) {
      this.openDialog();
    } else {
      this.notepadDialogRef.close();
    }
  }
}
