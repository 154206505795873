<div class="container">
    <!-- Filters -->
    <div appLinkedDatepickers [startDate]="startDate" [endDate]="endDate" class="filter-container">
        <mat-form-field class="filter" appearance="outline">
            <mat-label>Toets</mat-label>
            <mat-select [formControl]="selectedExam">
                <mat-option [value]="''">Alle toetsen</mat-option>
                <mat-option *ngFor="let exam of exams$ | async" [value]="exam.id">
                    {{ exam.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="datepicker filter" appearance="outline">
            <mat-label>Van</mat-label>
            <input autocomplete="off" matInput [formControl]="startDate" [matDatepicker]="pickerStart">
            <mat-datepicker-toggle *ngIf="startDate.value" matSuffix (click)="startDate.setValue(null)">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="datepicker filter" appearance="outline">
            <mat-label>Tot en met</mat-label>
            <input autocomplete="off" matInput [formControl]="endDate" [matDatepicker]="pickerEnd">
            <mat-datepicker-toggle *ngIf="endDate.value" matSuffix (click)="endDate.setValue(null)">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="filter small" appearance="outline">
            <mat-label>Min. gemaakt</mat-label>
            <input matInput type="number" min="0" [formControl]="minCompleted">
        </mat-form-field>

        <mat-checkbox [formControl]="myExamsOnly">Mijn toetsen</mat-checkbox>

        <app-button-with-spinner (click)="search()" [isLoading]="isLoading$ | async">
            Zoeken
        </app-button-with-spinner>
    </div>

    <!-- Exam instances table -->
    <mat-table [dataSource]="examResults$ | async">
        <ng-container *ngFor="let column of examResultColumns" [matColumnDef]="column.field">
            <mat-header-cell *matHeaderCellDef>{{ column.title }}</mat-header-cell>
            <mat-cell *matCellDef="let examInstance">
                <ng-container [ngSwitch]="column.type">
                    <span *ngSwitchCase="'date'">{{ examInstance[column.field] | date: 'dd-MM-yyyy' }}</span>
                    <span *ngSwitchCase="'user'">{{ examInstance[column.field] | username | async}}</span>
                    <span *ngSwitchCase="'duration'">{{ examInstance[column.field] | date: 'HH:mm': "UTC"}}</span>
                    <span *ngSwitchCase="'password'">
                        <span>{{ examInstance.showPassword ? examInstance[column.field] : '••••••' }}</span>
                        <button mat-icon-button (click)="togglePasswordVisibility(examInstance)" type="button">
                            <mat-icon>
                                {{ examInstance.showPassword ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                        </button>
                    </span>
                    <span *ngSwitchCase="'actions'">
                        <a *ngIf="examInstance.finishedExamSessions > 0" mat-stroked-button
                            [routerLink]="['/exam-results', examInstance.id]">Inzien</a>
                    </span>
                    <span *ngSwitchDefault>{{ examInstance[column.field] }}</span>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="progressBar">
            <mat-header-cell *matHeaderCellDef colspan="4" class="progress-bar-cell">
                <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async" color="accent"></mat-progress-bar>
            </mat-header-cell>
        </ng-container>

        <mat-header-row mat-header-row *matHeaderRowDef="examResultDisplayedColumns; sticky: true"
            class="main-header"></mat-header-row>
        <mat-header-row *matHeaderRowDef="['progressBar']; sticky: true" class="progress-bar-row"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: examResultDisplayedColumns;"></mat-row>
    </mat-table>

    <span *ngIf="(examResults$ | async)?.length === 0" class="placeholder-text">
        Geen gemaakte toetsen gevonden.
    </span>
</div>