import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragStart,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { constants } from 'shared/constants';

import { InteractionEvent } from 'shared/enums/interaction-event';
import { Unit } from 'shared/models/unit';
import {
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from 'shared/models/user-interaction-attempt';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TimeService } from 'src/app/services/time-service.interface';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { calculatorActions } from 'src/app/store/actions/calculator.actions';
import { notepadActions } from 'src/app/store/actions/notepad.actions';
import { scoreActions } from 'src/app/store/actions/score.actions';
import { studentActions } from 'src/app/store/actions/student.actions';
import { AppState } from 'src/app/store/reducers';
import { selectNotepadDialogIsOpen } from 'src/app/store/reducers/notepad.reducer';
import { selectUserUnitScore } from 'src/app/store/reducers/score.reducer';
import {
  selectIsInteractionReviewMode,
  selectUserUnitScorePath,
} from 'src/app/store/selectors/router.selectors';
import { environment } from '../../../../environments/environment';
import { InteractionModel } from '../../../../shared/enums/interaction-model';
import { utility } from '../../../../shared/helpers/utility';
import { Interaction } from '../../../../shared/models/interaction';
import {
  InteractionOptionHotspot,
  InteractionOptionPresentation,
  InteractionOptionSortOrder,
} from '../../../../shared/models/interaction-option';
import { AppUser } from '../../../../shared/models/user';
import { UserUnitScore } from '../../../../shared/models/user-unit-score';
import { Point, Rectangle } from '../../../../shared/types/shapes';
import { AppRouteParams } from '../../enums/route-params.enum';
import { findUnitContext } from '../../helpers/find-unit-context';
import { VideoHelper } from '../../helpers/video-helper';
import { AudioService } from '../../services/audio.service';
import { interactionActions } from '../../store/actions/interaction.actions';
import {
  BoxedString,
  selectCalculatorDialogIsOpen,
  selectLastCopiedValue,
} from '../../store/reducers/calculator.reducer';
import {
  selectCurrentExamInteractionId,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  selectExamSessionId,
  selectIsExamSession,
  selectNextExamInteractionRoute,
  selectPrevExamInteractionRoute,
  selectShowNextExamInteractionButton,
  selectShowPrevExamInteractionButton,
} from '../../store/reducers/exam.reducer';
import {
  AutoPlay,
  ClickedPoint,
  InteractionRouteParams,
  selectActivePresentationStep,
  selectAutoNextInput,
  selectClickedPointsWithCorrectness,
  selectCorrectReviewAnswers,
  selectDisableSkipButton,
  selectFeedback,
  selectHasDragged,
  selectImageUrl,
  selectInteractionLabels,
  selectInteractionUnit,
  selectInteractionUnitId,
  selectInteractionWeight,
  selectIsDirty,
  selectIsFirstTry,
  selectIsLocked,
  selectIsOkBtnDisabled,
  selectIsPresentation,
  selectIsReadonlyMode,
  selectIsSavingAnswer,
  selectIsSoundEnabled,
  selectIsTest,
  selectLastTry,
  selectLastUserInteractionAttempt,
  selectNextInteractionReviewRoute,
  selectNextInteractionRoute,
  selectNoImageScaling,
  selectOpenTextDraggables,
  selectParsedInteraction,
  selectPresentationIndicationArea,
  selectPrevInteractionReviewRoute,
  selectQuestion,
  selectQuestionSound,
  selectRadioOptions,
  selectRawInteraction,
  selectShowEndOfUnitDialog,
  selectShowFastForwardButton,
  selectShowNextButton,
  selectShowOkButton,
  selectShowSkipButton,
  selectShowStartOfTestDialog,
  selectShowStopButton,
  selectShowVideo,
  selectUserAnswers,
  selectVideoSource,
} from '../../store/reducers/interaction.reducer';
import { selectIsDevMode, selectUser } from '../../store/reducers/user.reducer';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../dialog/dialog.component';

@Component({
  selector: 'app-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss'],
})
export class InteractionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mediaContainer') mediaContainer: ElementRef<HTMLElement>;
  @ViewChild('interactionVideo') videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('interactionImage') image: ElementRef<HTMLImageElement>;
  @ViewChild('answerArea') answerArea: ElementRef<HTMLElement>;
  user$: Observable<AppUser>;
  rawInteraction$: Observable<Interaction>;
  parsedInteraction$: Observable<Interaction>;
  interactionModels = InteractionModel;
  feedback$: Observable<string>;
  nextInteractionRoute$: Observable<string | null>;

  activePresentationStep$: Observable<InteractionOptionPresentation | null>;

  isPresentation$: Observable<boolean>;
  presentationIndicationArea$: Observable<Rectangle | null>;

  showOkButton$: Observable<boolean>;
  showNextButton$: Observable<boolean>;
  showFastForwardButton$: Observable<boolean>;
  showSkipButton$: Observable<boolean>;
  showStopButton$: Observable<boolean>;
  disableSkipButton$: Observable<boolean>;

  unit$: Observable<Unit> | undefined;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  // maxTimerId: number;
  // timerExpired = false;
  noImageScaling$: Observable<boolean>;
  locked$: Observable<boolean>;
  question$: Observable<Interaction['question']>;
  isDevMode$: Observable<boolean>;
  debugEvents: InteractionEvent[];
  videoHelper: VideoHelper;
  showVideo$: Observable<boolean>;
  hasDragged$: Observable<boolean>;
  // openTextDraggables: number;
  openTextDraggables$: Observable<number>;
  // pointClicks: Point[];

  clickedPoints$: Observable<ClickedPoint[]>;

  radioOptions$: Observable<string[]>;
  radioSelected: string;
  radioSelectedPrev: string;

  userUnitScore$: Observable<UserUnitScore>;
  userUnitScorePath$: Observable<string>;
  shouldFindFocus: boolean;

  lastFocussedInput?: HTMLInputElement;
  lastCopiedValue$: Observable<BoxedString>;

  contextLocation$: Observable<string>;
  examSessionId$: Observable<string>;
  examSectionIndex$: Observable<number>;
  examSectionInteractionIndex$: Observable<number>;

  isExamSession$: Observable<boolean>;
  imageSrc$: Observable<string>;
  videoSrc$: Observable<[string, AutoPlay] | null>;

  model: InteractionModel;

  isSavingAnswer$: Observable<boolean>;
  isOkButtonDisabled$: Observable<boolean>;
  soundEnabled$: Observable<boolean>;
  autoNextInput$: Observable<boolean>;

  showNextExamInteractionButton$: Observable<boolean>;
  showPrevExamInteractionButton$: Observable<boolean>;

  nextExamInteractionRoute$: Observable<string>;
  prevExamInteractionRoute$: Observable<string>;
  isTest$: Observable<boolean>;

  unitId$: Observable<Unit['id']>;

  lastUserAttempts$: Observable<
    InteractionAttempt | StandaloneInteractionAttempt
  >;
  lastUserAnswers$: Observable<InteractionAttempt['answers']>;
  isReadonlyMode$: Observable<boolean>;
  isReviewMode$: Observable<boolean>;
  correctReviewAnswers$: Observable<string[]>;
  interactionWeight$: Observable<number | null>;
  inputsReady$: BehaviorSubject<NodeListOf<Element>>;
  prevInteractionReviewRoute$: Observable<string[]>;
  nextInteractionReviewRoute$: Observable<string[]>;
  breadcrumbSuffix = '../../';

  dateFormat = constants.longDateTimeFormat;

  isDirty$: Observable<boolean>;

  private inputChanges$ = new Subject<void>();

  interactionLabels$: Observable<string[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    public audio: AudioService,
    private dialog: MatDialog,
    private elRef: ElementRef,
    private router: Router,
    private store: Store<AppState>,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {
    this.isDevMode$ = this.store.select(selectIsDevMode);
    this.user$ = this.store.select(selectUser);
    this.rawInteraction$ = this.store.select(selectRawInteraction);
    this.userUnitScorePath$ = this.store.select(selectUserUnitScorePath);
    this.userUnitScore$ = this.store.select(selectUserUnitScore);
    this.nextInteractionRoute$ = this.store.select(selectNextInteractionRoute);
    this.parsedInteraction$ = this.store.select(selectParsedInteraction);

    this.lastCopiedValue$ = this.store.select(selectLastCopiedValue);
    this.unit$ = this.store.select(selectInteractionUnit);
    this.noImageScaling$ = this.store.select(selectNoImageScaling);
    this.videoHelper = new VideoHelper();
    this.showOkButton$ = this.store.select(selectShowOkButton);
    this.showNextButton$ = this.store.select(selectShowNextButton);
    this.locked$ = this.store.select(selectIsLocked);
    this.imageSrc$ = this.store.select(selectImageUrl);
    this.videoSrc$ = this.store.select(selectVideoSource);
    this.question$ = this.store.select(selectQuestion);
    this.hasDragged$ = this.store.select(selectHasDragged);
    this.openTextDraggables$ = this.store.select(selectOpenTextDraggables);
    this.showVideo$ = this.store.select(selectShowVideo);
    this.isPresentation$ = this.store.select(selectIsPresentation);
    this.activePresentationStep$ = this.store.select(
      selectActivePresentationStep
    );
    this.presentationIndicationArea$ = this.store.select(
      selectPresentationIndicationArea
    );

    this.clickedPoints$ = this.store.select(selectClickedPointsWithCorrectness);
    this.feedback$ = this.store.select(selectFeedback);

    this.showFastForwardButton$ = this.store.select(
      selectShowFastForwardButton
    );
    this.showSkipButton$ = this.store.select(selectShowSkipButton);
    this.disableSkipButton$ = this.store.select(selectDisableSkipButton);

    this.showStopButton$ = this.store.select(selectShowStopButton);

    this.radioOptions$ = this.store.select(selectRadioOptions);

    this.isSavingAnswer$ = this.store.select(selectIsSavingAnswer);
    this.isOkButtonDisabled$ = this.store.select(selectIsOkBtnDisabled);

    this.soundEnabled$ = this.store.select(selectIsSoundEnabled);

    this.autoNextInput$ = this.store.select(selectAutoNextInput);

    this.isExamSession$ = this.store.select(selectIsExamSession);
    this.examSessionId$ = this.store.select(selectExamSessionId);
    this.examSectionIndex$ = this.store.select(selectCurrentExamSectionIndex);
    this.examSectionInteractionIndex$ = this.store.select(
      selectCurrentExamSectionInteractionIndex
    );
    this.isTest$ = this.store.select(selectIsTest);

    this.unitId$ = this.store.select(selectInteractionUnitId);

    this.lastUserAttempts$ = this.store.select(
      selectLastUserInteractionAttempt
    );
    this.lastUserAnswers$ = this.store.select(selectUserAnswers);
    this.isReviewMode$ = this.store.select(selectIsInteractionReviewMode);
    this.isReadonlyMode$ = this.store.select(selectIsReadonlyMode);
    this.correctReviewAnswers$ = this.store.select(selectCorrectReviewAnswers);
    this.interactionWeight$ = this.store.select(selectInteractionWeight);

    this.prevInteractionReviewRoute$ = this.store.select(
      selectPrevInteractionReviewRoute
    );
    this.nextInteractionReviewRoute$ = this.store.select(
      selectNextInteractionReviewRoute
    );
    this.nextExamInteractionRoute$ = this.store.select(
      selectNextExamInteractionRoute
    );

    this.prevExamInteractionRoute$ = this.store.select(
      selectPrevExamInteractionRoute
    );

    this.isDirty$ = this.store.select(selectIsDirty);

    this.interactionLabels$ = this.store.select(selectInteractionLabels);
  }

  @HostListener('keyup', ['$event'])
  keyUpListener(event: KeyboardEvent) {
    // Trigger clickOk when pressing enter in an input field
    if (event.key === 'Enter') {
      this.locked$
        .pipe(first(), filter(utility.isFalsy), takeUntil(this.ngDestroyed$))
        .subscribe((locked) => {
          const eventTarget = event.target as HTMLElement;
          const tagName = eventTarget?.tagName?.toLocaleLowerCase();
          const type = (eventTarget as HTMLInputElement)?.type;
          const value = (eventTarget as HTMLInputElement)?.value;
          if (tagName === 'input' && type !== 'button' && value) {
            // Check if the OK button is disabled before calling clickOk
            this.isOkButtonDisabled$.pipe(first()).subscribe((isDisabled) => {
              if (!isDisabled) {
                this.clickOk();
              }
            });
          }
        });
    }

    // TODO arrow nav -> see https://stackoverflow.com/a/48150864/2407212
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event: FocusEvent) {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'text'
    ) {
      this.lastFocussedInput?.classList.remove('focused');
      this.lastFocussedInput = event.target;
      this.lastFocussedInput.classList.add('focused');
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    this.markNeutral(input);
    this.inputChanges$.next();

    // Automatically jump to next input when the current input is filled.
    combineLatest([this.autoNextInput$, this.store.select(selectIsFirstTry)])
      .pipe(first())
      .subscribe(([autoNextInput, isFirstTry]) => {
        if (!autoNextInput) {
          return;
        }

        if (isFirstTry) {
          this.findFocusInput(input);
        } else {
          this.selectFirstIncorrectInput();
        }
      });
  }

  markNeutral(input: HTMLElement) {
    input.classList.remove('incorrect', 'correct');
  }

  hotspotDragEnd(event: CdkDragEnd<InteractionOptionHotspot>) {
    const draggable = event.source.element.nativeElement;

    const image = this.image.nativeElement;
    const imageOrigin = new Point(image.offsetLeft, image.offsetTop);
    const imageRect = new Rectangle(
      imageOrigin,
      new Point(
        imageOrigin.x + image.offsetWidth,
        imageOrigin.y + image.offsetHeight
      )
    );

    const draggableOrigin = new Point(
      draggable.offsetLeft + draggable.offsetWidth / 2,
      draggable.offsetTop + draggable.offsetHeight / 2
    );

    const originsOffset = new Point(
      draggableOrigin.x - imageOrigin.x,
      draggableOrigin.y - imageOrigin.y
    );

    const relativePosition = event.source.getFreeDragPosition();

    const newPoint = new Point(
      originsOffset.x + relativePosition.x,
      originsOffset.y + relativePosition.y
    );

    const option = event.source.data;

    this.store.dispatch(
      interactionActions.dragEnd({
        draggedTo: newPoint,
        index: option.index,
        inImage: imageRect.isPointInside(newPoint),
        cdkDragFreeDragPosition: relativePosition,
      })
    );

    if (!environment.production) {
      console.log(`Drag end: ${newPoint}`, event, option);
    }
  }

  sortOrderDrop(event: CdkDragDrop<InteractionOptionSortOrder[]>) {
    const newOrder = [...event.container.data];
    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
    this.store.dispatch(interactionActions.setOptionsSortOrder(newOrder));
  }

  openTextDragStart(event: CdkDragStart) {
    // Add draggables.
    this.store.dispatch(
      interactionActions.openTextDraggablesConditionalIncrement(
        event.source.data
      )
    );
  }

  openTextDragEnd(event: CdkDragEnd) {
    const position = event.source.getFreeDragPosition();
    if (Math.abs(position.x) < 40 && Math.abs(position.y) < 40) {
      event.source.reset();
    }
  }

  ngOnInit() {
    this.reset();

    this.store.dispatch(calculatorActions.openDialog());
    this.store.dispatch(notepadActions.openDialog());

    // Re-init when route or params change.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(() => {
        this.reset();
      });

    this.parsedInteraction$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((parsedInteraction) => {
        if (parsedInteraction) {
          this.model = parsedInteraction.model;
        } else {
          this.model = undefined;
        }
      });

    this.inputsReady$ = new BehaviorSubject<NodeListOf<Element> | null>(null);

    this.parsedInteraction$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((parsedInteraction) => {
        if (parsedInteraction) {
          // Polling for inputs
          let attempts = 0;
          const maxAttempts = 300; // 6 seconds (6000ms / 20ms)
          const interval = setInterval(() => {
            const elements = document.querySelectorAll(
              '.gap input:not([type="button"])'
            );
            if (elements.length > 0) {
              this.inputsReady$.next(elements);
              clearInterval(interval);
            } else {
              attempts++;
              if (attempts >= maxAttempts) {
                this.inputsReady$.next(null); // Emit null after 6 seconds
                clearInterval(interval);
              }
            }
          }, 20);

          return () => {
            clearInterval(interval);
          };
        }
      });

    // TODO move to ngrx router store or something
    this.activatedRoute.paramMap
      .pipe(
        filter(utility.isTruthy),
        map((paramMap) => {
          const interactionIndex = paramMap.get(
            AppRouteParams.interactionIndex
          );

          const interactionRouteParams: InteractionRouteParams = {
            uid: paramMap.get(AppRouteParams.uid),
            projectId: paramMap.get(AppRouteParams.projectId),
            domainId: paramMap.get(AppRouteParams.domainId),
            chapterId: paramMap.get(AppRouteParams.chapterId),
            unitId: paramMap.get(AppRouteParams.unitId),
            interactionId: paramMap.get(AppRouteParams.interactionId),
            unitContext: paramMap.get(AppRouteParams.unitContext)
              ? findUnitContext(paramMap.get(AppRouteParams.unitContext))
              : undefined,
            interactionIndex: interactionIndex
              ? +interactionIndex - 1
              : undefined,
            examSessionId: paramMap.get(AppRouteParams.examSessionId),
            examSectionIndex: paramMap.get(AppRouteParams.examSectionIndex)
              ? parseInt(paramMap.get(AppRouteParams.examSectionIndex), 10)
              : undefined,
            examSectionInteractionIndex: paramMap.get(
              AppRouteParams.examSectionInteractionIndex
            )
              ? parseInt(
                  paramMap.get(AppRouteParams.examSectionInteractionIndex),
                  10
                )
              : undefined,
          };

          return interactionRouteParams;
        }),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((interactionRouteParams: InteractionRouteParams) => {
        this.store.dispatch(
          interactionActions.routeParamsChanged(interactionRouteParams)
        );
      });

    this.videoSrc$
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe(([video, autoPlay]) => {
        if (autoPlay) {
          this.videoHelper.play(video);
        } else {
          this.videoHelper.load(video);
        }
      });

    // TODO move to store
    // THIS IS NOT WORKING RIGHT NOW
    this.activatedRoute.paramMap
      .pipe(filter(utility.isTruthy))
      .subscribe((routeParams) => {
        if (!routeParams) {
          return;
        }

        if (!environment.production) {
          console.log('exam paramMap', routeParams);
        }

        const examSessionId = routeParams.get(AppRouteParams.examSessionId);
        const examSectionIndex = routeParams.get(
          AppRouteParams.examSectionIndex
        );
        const examSectionInteractionIndex = routeParams.get(
          AppRouteParams.examSectionInteractionIndex
        );

        if (
          !examSessionId ||
          !examSectionIndex ||
          !examSectionInteractionIndex
        ) {
          return;
        }

        this.store
          .select(selectCurrentExamInteractionId)
          .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
          .subscribe((interactionId) => {
            if (interactionId) {
              this.store.dispatch(
                interactionActions.loadInteraction(interactionId)
              );
            }
          });
      });

    // Focus the 'next' button
    this.isSavingAnswer$
      .pipe(
        filter(utility.isFalsy),
        debounceTime(0), // Give Angular time to update the DOM
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(() => {
        this.findFocusInput();
      });

    // Show end of unit dialog
    this.store
      .select(selectShowEndOfUnitDialog)
      .pipe(
        filter(({ showDialog }) => showDialog),
        map(({ isCorrect }) => isCorrect),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((isCorrect) => {
        this.endOfUnitDialog(isCorrect);
      });

    this.store
      .select(selectShowStartOfTestDialog)
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe(() => {
        this.startOfTestDialog()
          .afterClosed()
          .toPromise()
          .then(() => {
            (document.activeElement as any)?.blur?.();
            this.findFocusInput();
          });
      });

    this.lastCopiedValue$
      .pipe(
        filter((x) => !!x.value),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((newValue) => {
        const input = this.lastFocussedInput;
        if (
          !input ||
          !this.isEditableInput(input) ||
          input.value === newValue.value
        ) {
          return;
        }

        input.value = newValue.value;
        // This is needed to trigger the host listener.
        // FIXME: add test for this because this can easily break.
        input.dispatchEvent(new Event('input', { bubbles: true }));
      });

    this.showNextExamInteractionButton$ = this.store.select(
      selectShowNextExamInteractionButton
    );

    this.showPrevExamInteractionButton$ = this.store.select(
      selectShowPrevExamInteractionButton
    );

    // Disable inputs.
    this.locked$.pipe(takeUntil(this.ngDestroyed$)).subscribe((locked) => {
      if (locked) {
        this.makeCustomInputsReadOnly();
      }
    });

    // Update UI after answer was validated.
    this.store
      .select(selectLastTry)
      .pipe(
        distinctUntilChanged((prev, curr) => prev.tries === curr.tries),
        filter(({ tries }) => tries > 0),
        withLatestFrom(this.isExamSession$),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(
        ([
          { model, isTest, optionHotspots, isCorrect, answersCorrectness },
          isExamSession,
        ]) => {
          if (model === InteractionModel.multipleChoice && !isExamSession) {
            this.markRadio(isCorrect);
          }

          if (!isTest && !isExamSession) {
            answersCorrectness.forEach((answerCorrectness, i) => {
              this.markInput(i, answerCorrectness);
            });

            if (!isCorrect) {
              this.selectFirstIncorrectInput();
            }
          }

          this.shouldFindFocus = true;
        }
      );

    // Subscribe to sound
    this.store
      .select(selectQuestionSound)
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((sound) => {
        if (sound) {
          this.audio.play(sound);
        }
      });

    // Set audio settings from local storage
    const audioState = LocalStorageService.getAudioState();
    if (audioState) {
      this.audio.setVolume(audioState.volume);
      this.audio.toggleMute(audioState.muted);
    }

    // Save audio settings to local storage
    combineLatest([this.audio.muted$, this.audio.volume$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([muted, volume]) => {
        LocalStorageService.setAudioState({ muted, volume });
      });

    // Set answers as soon as the inputs are ready.
    combineLatest([
      this.isReadonlyMode$,
      this.lastUserAnswers$.pipe(
        filter(utility.isTruthy),
        takeUntil(this.ngDestroyed$)
      ),
      this.inputsReady$,
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([isReadonly, answers, inputs]) => {
        if (inputs?.length > 0) {
          this.setStringInputAnswers(answers, inputs);
          if (isReadonly) {
            this.makeCustomInputsReadOnly();
          }
        }
      });

    this.isReadonlyMode$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((isReadonlyMode) => {
        if (isReadonlyMode) {
          this.breadcrumbSuffix = '../../../';
        }
      });

    this.inputsReady$
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((inputs) => {
        if (this.shouldFindFocus) {
          this.findFocusInput();
          this.shouldFindFocus = false;
        }
      });

    this.inputChanges$
      .pipe(debounceTime(300), takeUntil(this.ngDestroyed$))
      .subscribe(() => {
        this.store.dispatch(interactionActions.setIsDirty({ isDirty: true }));
      });
  }

  setStringInputAnswers(answers: string[], inputs: NodeListOf<Element>) {
    inputs.forEach((input, index) => {
      if (answers[index]) {
        input.setAttribute('value', answers[index]);
      }
    });
  }

  ngAfterViewInit() {
    this.videoHelper.linkPlayer(this.videoPlayer.nativeElement);
  }

  ngOnDestroy() {
    this.store.dispatch(notepadActions.closeDialog({ clear: true }));
    this.store.dispatch(calculatorActions.closeDialog());

    this.store.dispatch(interactionActions.componentDestroyed());
    this.store.dispatch(studentActions.unsetStudent());
    this.store.dispatch(scoreActions.clearUserUnitScore());

    this.audio.pause();
    this.videoHelper.linkPlayer(null);
    // window.clearTimeout(this.maxTimerId);
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  reset() {
    this.audio.pause();
    this.videoHelper.pause();
    this.store.dispatch(calculatorActions.clear());
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    this.lastFocussedInput = null;
    this.radioSelected = null;
    this.radioSelectedPrev = null;
    this.shouldFindFocus = true;
    this.resetDirtyState();
  }

  async clickOk() {
    // Check readonly mode first
    const isReadonlyMode = await firstValueFrom(this.isReadonlyMode$);
    if (isReadonlyMode) {
      return;
    }

    const timestamp = this.timeService.now();

    switch (this.model) {
      case InteractionModel.pointAndClick:
      case InteractionModel.sortToHotspots:
      case InteractionModel.sortObjectsOrder: {
        // Answers are already in state and do not need to be sent
        this.store.dispatch(interactionActions.handleAnswer({ timestamp }));
        break;
      }
      case InteractionModel.multipleChoice: {
        if (
          this.radioSelected &&
          this.radioSelected !== this.radioSelectedPrev
        ) {
          this.radioSelectedPrev = this.radioSelected;
          this.store.dispatch(
            interactionActions.handleAnswer({
              uiAnswers: [this.radioSelected],
              timestamp,
            })
          );
        }
        break;
      }
      default: {
        const inputs = Array.from(
          this.answerArea.nativeElement.querySelectorAll(
            'input,select'
          ) as NodeListOf<HTMLInputElement | HTMLSelectElement>
        );
        const answers = inputs.map((input) => input?.value);

        // All inputs should be filled.
        const emptyInput = inputs[answers.findIndex((x) => !x)];
        if (emptyInput) {
          emptyInput.focus();
          return;
        }

        // No inputs should remain marked incorrect.
        const incorrect = inputs.find((i) => i.classList.contains('incorrect'));
        if (incorrect) {
          if (incorrect instanceof HTMLInputElement) {
            incorrect.select();
          }
          if (incorrect instanceof HTMLSelectElement) {
            incorrect.focus();
          }
          return;
        }

        this.store.dispatch(
          interactionActions.handleAnswer({ uiAnswers: answers, timestamp })
        );
      }
    }
  }

  clickStop() {
    const projectId = this.activatedRoute.snapshot.paramMap.get(
      AppRouteParams.projectId
    );
    const domainId = this.activatedRoute.snapshot.paramMap.get(
      AppRouteParams.domainId
    );
    const chapterId = this.activatedRoute.snapshot.paramMap.get(
      AppRouteParams.chapterId
    );

    // If any of the required params are missing, navigate to root
    if (!projectId || !domainId || !chapterId) {
      this.router.navigate(['/']);
      return;
    }

    // Navigate to the chapter overview directly
    this.router.navigate(['/', projectId, domainId, chapterId]);
  }

  clickVideoPlayPause() {
    this.videoHelper.togglePlay();
  }

  async routeNext(fastForward: boolean = false) {
    // Check readonly mode first
    const isReadonlyMode = await firstValueFrom(this.isReadonlyMode$);
    if (isReadonlyMode) {
      return;
    }

    if (fastForward) {
      this.router.navigate(['../../', 'test', '1'], {
        relativeTo: this.activatedRoute,
      });
      return;
    }

    // Get the next route
    const route = await firstValueFrom(this.nextInteractionRoute$);
    const [path, index] = route.split('/');
    this.router.navigate(['../../', path, index], {
      relativeTo: this.activatedRoute,
    });
  }

  routePrevReview() {
    this.prevInteractionReviewRoute$
      .pipe(first())
      .subscribe((route: string[]) => {
        if (route) {
          this.router.navigate(['../../../', ...route], {
            relativeTo: this.activatedRoute,
          });
        }
      });
  }

  routeNextReview() {
    this.nextInteractionReviewRoute$
      .pipe(first())
      .subscribe((route: string[]) => {
        if (route) {
          this.router.navigate(['../../../', ...route], {
            relativeTo: this.activatedRoute,
          });
        }
      });
  }

  routeExamNext() {
    combineLatest([this.isDirty$, this.nextExamInteractionRoute$])
      .pipe(first())
      .subscribe(([isDirty, route]) => {
        if (isDirty) {
          this.showUnsavedAnswerDialog()
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.navigateToExamInteractionRoute(route);
              }
            });
        } else {
          this.navigateToExamInteractionRoute(route);
        }
      });
  }

  routeExamPrevious() {
    combineLatest([this.isDirty$, this.prevExamInteractionRoute$])
      .pipe(first())
      .subscribe(([isDirty, route]) => {
        if (isDirty) {
          this.showUnsavedAnswerDialog()
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.navigateToExamInteractionRoute(route);
              }
            });
        } else {
          this.navigateToExamInteractionRoute(route);
        }
      });
  }

  clickVideo(event: MouseEvent) {
    // Play video if it is not playing.
    if (!this.videoHelper.ended()) {
      const wasPaused = this.videoHelper.isPaused();
      this.videoHelper.play();
      if (wasPaused) {
        return;
      }
    }

    const point = {
      x: event.offsetX,
      y: event.offsetY,
    };

    this.store.dispatch(interactionActions.clickMediaArea(point));
  }

  clickImage(event: MouseEvent) {
    // if (this.locked$.getValue()) {
    //   return;
    // }

    const point = {
      x: event.offsetX,
      y: event.offsetY,
    };

    this.store.dispatch(interactionActions.clickMediaArea(point));

    // // Other clicks
    // if (
    //   this.parsedInteraction.model !== InteractionModel.pointAndClick ||
    //   !this.parsedInteraction.optionsImage
    // ) {
    //   return;
    // }

    // const pointClick = new Point(event.offsetX, event.offsetY);
    // const clicksNeeded = this.parsedInteraction.optionsImage.filter(
    //   (o) => o.correct
    // ).length;

    // this.pointClicks.push(pointClick);
    // this.showPointSpot(pointClick, this.pointClicks.length);

    // if (this.pointClicks.length > clicksNeeded) {
    //   this.pointClicks.length = 0;
    //   this.pointClicks.push(pointClick);
    //   this.removePointSpots();
    //   this.showPointSpot(pointClick, 1);
    // }

    // if (this.pointClicks.length === clicksNeeded) {
    //   const clickToString = this.pointClicks.map((c) => `${c.x},${c.y}`);
    //   this.handleAnswer(clickToString);
    // }
  }

  showParsed() {
    this.parsedInteraction$
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((parsedInteraction) => {
        this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
          data: {
            title: 'Parsed interaction',
            code: JSON.stringify(parsedInteraction, null, 2),
            preset: DialogPreset.ok,
          },
        });
      });
  }

  showRaw() {
    this.rawInteraction$
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((interaction) => {
        this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
          data: {
            title: 'Raw interaction',
            code: JSON.stringify(interaction, null, 2),
            preset: DialogPreset.ok,
          },
        });
      });
  }

  markRadio(correct: boolean | null) {
    const radioGroup =
      this.answerArea.nativeElement.querySelector('mat-radio-group');

    radioGroup.classList.remove('correct', 'incorrect');

    if (correct !== null) {
      radioGroup.classList.add(correct ? 'correct' : 'incorrect');
    }
  }

  clickCalculator() {
    combineLatest([
      this.store.select(selectCalculatorDialogIsOpen),
      this.store.select(selectNotepadDialogIsOpen),
      this.isReadonlyMode$,
    ])
      .pipe(take(1))
      .subscribe(
        ([calculatorDialogIsOpen, notepadDialogIsOpen, isReadonly]) => {
          const bothOpened = calculatorDialogIsOpen && notepadDialogIsOpen;
          const bothClosed = !calculatorDialogIsOpen && !notepadDialogIsOpen;

          if (bothOpened || bothClosed || notepadDialogIsOpen) {
            this.store.dispatch(calculatorActions.toggleDialog());
          }

          if (bothOpened || bothClosed || calculatorDialogIsOpen) {
            this.store.dispatch(notepadActions.toggleDialog());
          }
        }
      );
  }

  // Moved to store
  // private showPointSpot(point: Point, num: number, correct?: boolean) {
  //   const circle = document.createElement('div');
  //   circle.classList.add('point-spot');
  //   if (typeof correct === 'boolean') {
  //     circle.classList.add(correct ? 'correct' : 'incorrect');
  //   }
  //   circle.textContent = '' + num;
  //   circle.style.left =
  //     this.image.nativeElement.offsetLeft + point.x - 7 + 'px';
  //   circle.style.top = this.image.nativeElement.offsetTop + point.y - 7 + 'px';

  //   // Check if we're not the first.
  //   const nodes = document.querySelectorAll('.point-spot');
  //   if (nodes.length) {
  //     nodes[nodes.length - 1].after(circle);
  //   } else {
  //     this.image.nativeElement.after(circle);
  //   }
  // }

  private showUnsavedAnswerDialog() {
    return this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Niet-opgeslagen antwoord',
        text: 'Je antwoord is niet opgeslagen. Het gaat verloren als je verder gaat zonder op OK te drukken.',
        options: [
          { text: 'Annuleren', value: false },
          { text: 'Doorgaan', value: true },
        ],
      },
    });
  }

  private navigateToExamInteractionRoute(route: string) {
    const [path, index] = route.split('/');
    this.router.navigate(['../../', path, index], {
      relativeTo: this.activatedRoute,
    });
  }

  private startOfTestDialog() {
    return this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Toets',
        text: 'Je begint nu aan de toets.',
        preset: DialogPreset.ok,
      },
    });
  }

  private endOfUnitDialog(passed: boolean) {
    if (passed) {
      const dialogRef = this.dialog.open<DialogComponent, DialogData>(
        DialogComponent,
        {
          disableClose: true,
          data: {
            title: 'Gehaald',
            text: 'Je hebt de leereenheid succesvol afgerond.',
            preset: DialogPreset.ok,
          },
        }
      );
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(() => {
          this.clickStop();
        });
    } else {
      const dialogRef = this.dialog.open<DialogComponent, DialogData>(
        DialogComponent,
        {
          disableClose: true,
          data: {
            title: 'Helaas',
            text: 'Dit is nog niet voldoende. Wil je doorgaan binnen deze leereenheid?',
            options: [
              { text: 'Stop', value: false },
              { text: 'OK', value: true, focus: true },
            ],
          },
        }
      );
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            this.routeNext();
          } else {
            this.clickStop();
          }
        });
    }
  }

  // TODO not used
  private removePointSpot(num: number) {
    Array.from(document.querySelectorAll('.point-spot'))
      .filter((spot) => spot.textContent.includes('' + num))
      .forEach((spot) => spot.remove());
  }

  private findFocusInput(startElem: HTMLElement = null) {
    this.store
      .select(selectCalculatorDialogIsOpen)
      .pipe(take(1))
      .subscribe((calculatorDialogIsOpen) => {
        if (
          this.dialog.openDialogs.length &&
          !(this.dialog.openDialogs.length === 2 && calculatorDialogIsOpen)
        ) {
          return;
        }

        const inputs: HTMLElement[] = Array.from(
          this.elRef?.nativeElement?.querySelectorAll(
            'input:enabled:not(:read-only):not([type="radio"]), button:enabled:not(.skip-focus)'
          ) || []
        );

        if (!startElem && inputs.length) {
          // Return when we already have focus.
          if (inputs.some((x) => x === document.activeElement)) {
            return;
          }
          // No elem was provided, focus the first.
          inputs[0].focus();
          return;
        }

        const index = inputs.findIndex((input) => input === startElem);
        if (index !== -1 && inputs[index + 1]) {
          // Focus next.
          inputs[index + 1].focus();
        }
      });
  }

  private selectFirstIncorrectInput() {
    const input = this.elRef?.nativeElement?.querySelector(
      'input.incorrect'
    ) as HTMLInputElement;

    input?.select();
  }

  private makeCustomInputsReadOnly() {
    // Make inputs readonly.
    (
      this.answerArea?.nativeElement?.querySelectorAll(
        '.gap input:not([type="button"])'
      ) as NodeListOf<HTMLInputElement>
    )?.forEach((input) => (input.readOnly = true));
  }

  private isEditableInput(element: any): boolean {
    if (!element) {
      return false;
    }

    if (
      (element.tagName === 'INPUT' ||
        element.tagName === 'TEXTAREA' ||
        element.tagName === 'SELECT') &&
      !element.hasAttribute('disabled') &&
      !element.hasAttribute('readonly')
    ) {
      return true;
    }

    return false;
  }

  private markInput(index: number, correct: boolean) {
    if (!this.answerArea) {
      return;
    }

    const inputs = Array.from(
      this.answerArea.nativeElement.querySelectorAll(
        'input,select'
      ) as NodeListOf<HTMLInputElement | HTMLSelectElement>
    );

    if (!inputs || !inputs[index]) {
      return;
    }

    inputs[index].classList.add(correct ? 'correct' : 'incorrect');

    if (correct) {
      if (inputs[index] instanceof HTMLInputElement) {
        (inputs[index] as HTMLInputElement).readOnly = true;
      }
      if (inputs[index] instanceof HTMLSelectElement) {
        (inputs[index] as HTMLSelectElement).disabled = true;
      }
    }
  }

  private resetDirtyState() {
    this.store.dispatch(interactionActions.setIsDirty({ isDirty: false }));
  }
}
