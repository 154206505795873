import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { selectUserRoles } from '../../store/reducers/user.reducer';
import { AppRoute, AppRoutes } from '../../types/route-data';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  childRoutes$: Observable<AppRoutes>;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.childRoutes$ = this.store.select(selectUserRoles).pipe(
      map((userRoles) => {
        const adminRoute = this.router.config.find(
          (r) => r.component === AdminComponent
        );
        return (
          adminRoute?.children?.filter(
            (route: AppRoute) =>
              userRoles &&
              Array.from(userRoles.values()).some((userRole) =>
                route.data?.roles?.has(userRole)
              )
          ) || []
        );
      }),
      takeUntil(this.ngDestroyed$)
    );
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
