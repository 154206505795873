/* eslint-disable @typescript-eslint/naming-convention */
import * as Guard from '../extensions/guard';
import * as TypeExtensions from '../extensions/type.extensions';

const allowedChars = Array.from('0123456789.,+-*x/%:;()\xd7\xf7');
const machineToEuropeanMap = {
  x: '\xd7',
  '*': '\xd7',
  '/': '\xf7',
  '.': ',',
};
const europeanToMachineMap = {
  '\xd7': '*',
  x: '*',
  ':': '/',
  ';': '/',
  '\xf7': '/',
  ',': '.',
};
const nonRepeatableEuropeanChars = ['+', '-', ',', '\xd7', '\xf7'];

export class MathFormulaParser {
  static parseToMachineFormat(
    formula: string,
    blockedOperators: string[]
  ): string {
    Guard.isString(formula);
    formula = MathFormulaParser.filterCharacters(formula);
    formula = MathFormulaParser.blockOperators(formula, blockedOperators);
    formula = MathFormulaParser.mapCharacters(formula, machineToEuropeanMap);
    formula = MathFormulaParser.replaceDoubleEuropeanOperators(formula);
    formula = MathFormulaParser.mapCharacters(formula, europeanToMachineMap);
    return formula;
  }

  static parseToEuropeanFormat(
    formula: string,
    blockedOperators: string[]
  ): string {
    Guard.isString(formula);
    formula = MathFormulaParser.filterCharacters(formula);
    formula = MathFormulaParser.blockOperators(formula, blockedOperators);
    formula = MathFormulaParser.mapCharacters(formula, machineToEuropeanMap);
    formula = MathFormulaParser.replaceDoubleEuropeanOperators(formula);
    return formula;
  }

  private static blockOperators(formula: string, blockedOperators: string[]) {
    return Array.from(formula)
      .filter(
        (character) =>
          TypeExtensions.isNullOrUndefined(blockedOperators) ||
          !blockedOperators.includes(character)
      )
      .join('');
  }

  private static filterCharacters(value: string) {
    return Array.from(value)
      .filter((character) => allowedChars.includes(character))
      .join('');
  }

  private static mapCharacters(value: string, map: Record<string, string>) {
    return Array.from(value)
      .map((character) => (character in map ? map[character] : character))
      .join('');
  }

  private static replaceDoubleEuropeanOperators(value: string) {
    return Array.from(value)
      .reduce((acc, character) => {
        if (
          nonRepeatableEuropeanChars.includes(character) &&
          nonRepeatableEuropeanChars.includes(acc[acc.length - 1])
        ) {
          acc.pop();
        }
        acc.push(character);
        return acc;
      }, [])
      .join('');
  }
}
