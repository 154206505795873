<div class="container">
    <div class="filter-container">
        <app-search-widget type="users" label="Student" placeholder="Voer naam in..." (selected)="filterUser($event)"
            (queryChanged)="userInputChanged($event)">
        </app-search-widget>
        <app-button-with-spinner icon="search" (click)="search()" [isLoading]="isLoading$ | async"
            [disabled]="!selectedUserId">
            Zoeken
        </app-button-with-spinner>
    </div>

    <div class="results-container">
        <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>

        <ng-container *ngIf="(sessions$ | async) as sessions">
            <table mat-table [dataSource]="sessions" class="results-table" *ngIf="sessions.length > 0">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ examSessionColumns[0].title }}</th>
                    <td mat-cell *matCellDef="let session">{{ session.examTitle }}</td>
                </ng-container>

                <!-- Custom Title Column -->
                <ng-container matColumnDef="examInstanceCustomTitle">
                    <th mat-header-cell *matHeaderCellDef>{{ examSessionColumns[1].title }}</th>
                    <td mat-cell *matCellDef="let session">{{ session.examInstanceCustomTitle }}</td>
                </ng-container>

                <!-- Finished Date Column -->
                <ng-container matColumnDef="finishedDate">
                    <th mat-header-cell *matHeaderCellDef>{{ examSessionColumns[2].title }}</th>
                    <td mat-cell *matCellDef="let session">
                        {{ isFinalizedExamSession(session) ? (session.finishedAt | date:'short') : '-' }}
                    </td>
                </ng-container>

                <!-- Score Column -->
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef>{{ examSessionColumns[3].title }}</th>
                    <td mat-cell *matCellDef="let session">{{ formatScore(session) }}</td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{ examSessionColumns[4].title }}</th>
                    <td mat-cell *matCellDef="let session">
                        <div class="button-container">
                            <button mat-stroked-button *ngIf="isFinalizedExamSession(session)"
                                (click)="showExamResult(session)">
                                Score
                            </button>
                            <button mat-stroked-button *ngIf="isFinalizedExamSession(session)"
                                (click)="navigateToExamReview(session)">
                                Inzien
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <span *ngIf="sessions.length === 0" class="no-results">
                Geen resultaten
            </span>
        </ng-container>
    </div>
</div>