// Dutch language phonetic replacement patterns
const phoneticReplacements: [RegExp, string][] = [
  [/th/g, 't'],
  [/ph/g, 'f'],
  [/sch/g, 's'],
  [/ch/g, 'g'],
  [/dt/g, 't'],
  [/d/g, 't'],
  [/eeuw/g, 'ew'],
  [/ei/g, 'y'],
  [/ij/g, 'y'],
  [/ce/g, 'se'],
  [/ci/g, 'si'],
  [/tsie/g, 'si'],
  [/tie/g, 'si'],
  [/ie/g, 'i'],
  [/eaux/g, 'os'],
  [/eau/g, 'o'],
  [/eu/g, 'u'],
  [/oe/g, 'u'],
  [/au/g, 'u'],
  [/ou/g, 'u'],
  [/c/g, 'k'],
  [/z/g, 's'],
  [/v/g, 'f'],
  [/b/g, 'p'],
  [/x/g, 'ks'],
  [/qu/g, 'kw'],
];

/**
 * Removes diacritical marks from characters, replacing them with their base form.
 * @param text - The input string containing diacritical marks
 * @returns The string with all diacritical marks removed
 */
export const removeDiacriticalMarks = (text: string): string =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Transforms text using Dutch phonetic rules.
 *
 * The transformation process:
 * 1. Removes diacritical marks
 * 2. Converts to lowercase
 * 3. Applies phonetic replacement patterns
 *
 * @param text - The input text to transform
 * @returns The phonetically transformed text
 */
export const phoneticTransform = (text: string): string => {
  // Remove diacritical marks
  const result = removeDiacriticalMarks(text);

  // Apply phonetic replacements to lowercase text
  return phoneticReplacements.reduce(
    (str, [pattern, replacement]) => str.replace(pattern, replacement),
    result.toLowerCase()
  );
};
