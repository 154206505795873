import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import {
  selectIsDbUserLoaded,
  selectUserHasAppAccess,
} from 'src/app/store/reducers/user.reducer';

export const licenseGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectIsDbUserLoaded).pipe(
    filter((isLoaded) => isLoaded),
    switchMap(() => store.select(selectUserHasAppAccess)),
    map((licensed) => {
      if (licensed) {
        return true;
      }
      return router.parseUrl('license-expired');
    }),
    first()
  );
};
