import * as Guard from './guard';
import * as TypeExtensions from './type.extensions';

export const empty = <T>(): T[] => [];

export const isNullOrUndefinedOrEmpty = <T>(value: T[]): boolean => {
  if (TypeExtensions.isNullOrUndefined(value)) {
    return true;
  }
  return Array.isArray(value) && !value.length;
};

export const groupBy = <T>(
  items: T[],
  key: (item: T) => string
): [string, T[]][] => {
  Guard.isNotNullOrUndefined(items);
  Guard.isNotNullOrUndefined(key);
  const accumulation = items.reduce(
    (accumulator: Record<string, T[]>, item) => {
      const groupKey = key(item);
      if (!accumulator[groupKey]) {
        accumulator[groupKey] = [];
      }
      accumulator[groupKey].push(item);
      return accumulator;
    },
    {}
  );
  return Object.keys(accumulation).map((groupKey) => [
    groupKey,
    accumulation[groupKey],
  ]);
};
