import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { toTop } from 'src/app/misc/dialog-utils';

export type NotepadDialogData = Record<string, any>;
@Component({
  selector: 'app-notepad-dialog',
  templateUrl: './notepad-dialog.component.html',
  styleUrls: ['./notepad-dialog.component.scss'],
})
export class NotepadDialogComponent {
  public static panelClass = 'notepad-dialog';
  constructor(
    @Inject(MAT_DIALOG_DATA) data: NotepadDialogData,
    store: Store,
    private cdk: OverlayContainer
  ) {}

  moveToTop = () => toTop(NotepadDialogComponent.panelClass, this.cdk);
}
