import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { NotepadLine, NotepadLineForStorage } from 'shared/models/notepad-line';
import { notepadActions } from '../actions/notepad.actions';

export type NotepadFeatureState = EntityState<NotepadLine> & {
  dialogIsOpen?: boolean;
  isReadonlyMode: boolean;
};

export const adapter = createEntityAdapter<NotepadLine>();

export const initialState: NotepadFeatureState = adapter.getInitialState({
  dialogIsOpen: false,
  isReadonlyMode: false,
});

export const notepadReducer = createReducer(
  initialState,
  on(notepadActions.add, (state, line): NotepadFeatureState => {
    if (state.isReadonlyMode) {
      return state;
    }
    return adapter.addOne(line, state);
  }),
  on(
    notepadActions.reset,
    (state): NotepadFeatureState => ({
      ...adapter.removeAll(state),
      isReadonlyMode: false,
    })
  ),
  on(notepadActions.update, (state, { id, content }): NotepadFeatureState => {
    if (state.isReadonlyMode) {
      return state;
    }

    return adapter.updateOne({ id, changes: { content } }, state);
  }),
  on(notepadActions.remove, (state, { id }): NotepadFeatureState => {
    if (state.isReadonlyMode) {
      return state;
    }

    return adapter.removeOne(id, state);
  }),
  on(
    notepadActions.setDialogState,
    (state, action): NotepadFeatureState => ({
      ...state,
      dialogIsOpen: action.isOpen,
    })
  ),
  on(
    notepadActions.setReadonlyMode,
    (state, action): NotepadFeatureState => ({
      ...state,
      isReadonlyMode: action.isReadonlyMode,
    })
  )
);

export const selectNotepadState =
  createFeatureSelector<NotepadFeatureState>('notepad');

const selectors = adapter.getSelectors();

export const selectNotepadDialogIsOpen = createSelector(
  selectNotepadState,
  (state) => state.dialogIsOpen
);

export const selectNotepadLines = createSelector(selectNotepadState, (state) =>
  selectors.selectAll(state)
);

export const selectNotepadLinesForStorageAsArray = createSelector(
  selectNotepadState,
  (state) =>
    selectors.selectAll(state).map((nl) => {
      const notepadLineForStorage: NotepadLineForStorage = {
        source: nl.source,
        content: nl.content,
      };
      return notepadLineForStorage;
    })
);
