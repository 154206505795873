import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, firstValueFrom } from 'rxjs';
import { ExamSession, ExamSessionFinalized } from 'shared/models/exam-session';
import { SearchHit } from 'src/app/components/search-widget/search-widget.component';
import { calculateExamSessionScorePercentage } from 'src/app/helpers/exam-score-helper';
import { ExamService } from 'src/app/services/exam.service';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { AppState } from 'src/app/store/reducers';
import {
  ExamFinishedComponent,
  dialogWidth,
} from '../../../exam-finished/exam-finished.component';

@Component({
  selector: 'app-exam-overview-teacher-students',
  templateUrl: './exam-overview-teacher-students.component.html',
  styleUrls: ['./exam-overview-teacher-students.component.scss'],
})
export class ExamOverviewTeacherStudentsComponent implements OnDestroy {
  private ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  examSessionColumns = [
    { field: 'name', title: 'Toets' },
    { field: 'examInstanceCustomTitle', title: 'Titel' },
    { field: 'finishedDate', title: 'Gemaakt op' },
    { field: 'score', title: 'Score' },
    { field: 'actions', title: 'Acties' },
  ];

  displayedColumns = this.examSessionColumns.map((c) => c.field);

  isLoading$ = new BehaviorSubject<boolean>(false);
  sessions$ = new BehaviorSubject<ExamSession[]>([]);
  selectedUserId: string | null = null;

  constructor(
    public examService: ExamService,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  userInputChanged(text: string): void {
    if (!text) {
      this.selectedUserId = null;
    }
  }

  filterUser(hit: SearchHit): void {
    this.selectedUserId = hit.objectID;
    if (this.sessions$.value.length === 0) {
      this.search();
    }
  }

  async search(): Promise<void> {
    if (!this.selectedUserId) {
      return;
    }

    this.isLoading$.next(true);

    try {
      const sessions = await firstValueFrom(
        this.examService.getExamSessionsForUser(this.selectedUserId)
      );
      this.sessions$.next(sessions);
    } catch (error) {
      this.sessions$.next([]);
      console.error(error);
      this.store.dispatch(
        showSnackbar({
          message: 'Er is een fout opgetreden bij het ophalen van de toetsen.',
          action: 'Sluiten',
          duration: 4000,
        })
      );
    } finally {
      this.isLoading$.next(false);
    }
  }

  formatScore(row: ExamSessionFinalized): string | null {
    if (this.examService.isFinalizedExamSessionWrapped(row)) {
      const percentage = calculateExamSessionScorePercentage(row) ?? 0;
      return `${percentage}%`;
    }
    return null;
  }

  isFinalizedExamSession(session: ExamSession): boolean {
    return this.examService.isFinalizedExamSessionWrapped(session);
  }

  async showExamResult(examSession: ExamSession): Promise<void> {
    try {
      const result = await firstValueFrom(
        this.examService.getExamSessionResult(examSession.id)
      );
      this.dialog.open(ExamFinishedComponent, {
        width: dialogWidth,
        data: {
          ...result,
          hideGrade: examSession.hideGrade,
        },
      });
    } catch (error) {
      console.error(error);
      this.store.dispatch(
        showSnackbar({
          message: 'Het resultaat kon niet worden geladen.',
          action: 'Sluiten',
          duration: 5000,
        })
      );
    }
  }

  navigateToExamReview(session: ExamSession): void {
    const url = `exam-session-review/${session.id}/0/0`;
    window.open(url, '_blank');
  }
}
