import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';

export const dateAfter =
  (referenceDate: Moment): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const date = moment(control.value);
    if (date.isBefore(referenceDate)) {
      return { invalidDate: true };
    }
  };
