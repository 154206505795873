import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { calculatorActions } from 'src/app/store/actions/calculator.actions';
import {
  selectIsAllowedToCalculatePercentages,
  selectIsReadonlyMode,
} from 'src/app/store/reducers/interaction.reducer';
import {
  CalculatorFormState,
  selectForm,
} from '../../store/reducers/calculator.reducer';
import { AppState } from '../../store/reducers/index';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit, OnDestroy {
  @Output() calculated = new EventEmitter<string>();
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  form$: Observable<FormGroupState<CalculatorFormState>>;
  isAllowedToCalculatePercentages$: Observable<boolean>;

  isReadonlyMode$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.form$ = store.select(selectForm);
    this.isAllowedToCalculatePercentages$ = store.select(
      selectIsAllowedToCalculatePercentages
    );
    this.isReadonlyMode$ = store.select(selectIsReadonlyMode);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // Handle the key unless something else in the DOM is focused
    if (
      !['INPUT', 'TEXTAREA', 'BUTTON'].includes(
        document.activeElement.tagName
      ) &&
      !document.activeElement.classList.contains('clear-calc-number-listener')
    ) {
      const { key } = event;

      switch (key) {
        case 'Enter':
        case '=':
          this.onSubmit();
          break;
        case 'Delete':
          this.onClearButtonPress();
          break;
        case 'Backspace':
          this.onBackspaceButtonPress();
          break;
        default:
          if (key.length === 1) {
            this.store.dispatch(calculatorActions.input({ value: key }));
          }
          break;
      }
    }

    // Revalidate (needed if typed directly in the input)
    this.store.dispatch(calculatorActions.revalidate());

    if (
      event.target instanceof HTMLElement &&
      event.target.matches('.calculator input') &&
      event.key === '='
    ) {
      this.onSubmit();
    }
  }

  public onSubmit() {
    this.store.dispatch(calculatorActions.calculate());
  }

  public onClearButtonPress() {
    this.store.dispatch(calculatorActions.clear());
  }

  public onCopyButtonPress() {
    this.store.dispatch(calculatorActions.copy());
  }

  public onBackspaceButtonPress() {
    this.store.dispatch(calculatorActions.backspace());
  }

  public onButtonPress(value: string) {
    this.store.dispatch(calculatorActions.input({ value }));
    // Blur focus to allow typing again.
    if (document.activeElement.tagName === 'BUTTON') {
      (document.activeElement as HTMLElement).blur();
    }
  }

  ngOnInit() {
    this.isAllowedToCalculatePercentages$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((isAllowedToCalculatePercentages) => {
        this.store.dispatch(
          calculatorActions.blockOperators({
            operators: !isAllowedToCalculatePercentages ? ['%'] : [],
          })
        );
      });
  }

  ngOnDestroy() {
    this.store.dispatch(calculatorActions.destroyed());
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
